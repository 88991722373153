body {
    overflow: hidden !important;
    -webkit-font-smoothing: antialiased;
    /* Chrome, Safari */
}

.container-fluid {
    width: 100%;
    padding-right: 22px !important;
    padding-left: 22px !important;
    padding-top: 22px !important;
    margin-right: auto;
    margin-left: auto;
    /*margin-top: 62px;*/
}

.container-fluid:has(.login-container) {
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
}

.side-menu-navbar .active {
    background-color: #13294b;
}

.layout-container {
    overflow: hidden;
    overflow-y: auto;
}

.dropup,
.dropright,
.dropleft {
    position: relative;
}

.layout-dropdown-menu-right {
    left: auto;
    padding: 0.3rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    font-size: 14px;
}

.layout-dropdown-menu-right span {
    margin: 10px;
    color: black;
}

.layout-dropdown-menu-right i {
    color: #555464 !important;
    text-shadow: #ffffff 1px 1px 1px;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    /*    color: #555464 !important;*/
    text-shadow: #ffffff 1px 1px 1px;
}

.nav-link span {
    color: black;
    text-shadow: none;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.recipient-email .Select-placeholder {
    display: none;
}

.recipient-email .Select-input {
    width: 100%
}

.ml-auto {
    float: right;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 600px;
    }

    .client-tracking-modal>.modal-dialog {
        max-width: 900px;
    }

    .view-access-code-modal>.modal-dialog {
        max-width: 1230px;
    }

    .report-problem-modal>.modal-dialog {
        max-width: 800px;
    }

    .my-login-history>.modal-dialog {
        max-width: 900px;
    }
}

@media (min-width: 768px) {
    .modal-dialog {
        max-width: 600px;
    }

    .send-reminder-modal>.modal-dialog {
        max-width: 644px;
    }

    .client-tracking-modal>.modal-dialog {
        max-width: 900px;
    }

    .view-access-code-modal>.modal-dialog {
        max-width: 1230px;
    }

    .report-problem-modal>.modal-dialog {
        max-width: 800px;
    }

    .my-login-history>.modal-dialog {
        max-width: 900px;
    }
    .edit-client-info-modal>.modal-dialog {
        max-width: 610px;
    }
    .sf-esign-details-modal>.modal-dialog {
        max-width: 870px !important;
    }
}

@media (min-width: 992px) {
    .sfPanel-body .col-sm-1 {
        flex: 0 0 10.333333%;
        max-width: 10.333333%;
    }
}

@media (min-width: 1400px) {
    .sfPanel-body .col-sm-1 {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
    }

    .edit-client-info-modal.modal-dialog {
        max-width: 610px !important;
    }
}

#wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

#wrapper #content-wrapper {
    width: 100%;
    height: calc(100vh - 70px);
    overflow-x: hidden;
    overflow: auto;
}

.form-control::placeholder {
    color: lightgray !important;
    font-family: 'Mulish';
}


/* footer.sticky-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 20px;
    background-color: rgb(241, 236, 236);
    width: 100%;
    font-size: 11px;
    padding: 0px 7px 0px 7px;
}

footer.sticky-footer .copyright {
    line-height: 1;
    font-size: 0.8rem;
} */

body.sidebar-toggled footer.sticky-footer {
    width: 100%;
}

.form-control,
.react-bs-table-bordered,
.btn,
.Select-control {
    border-radius: 0px !important;
}

.recipient-msg-clear>.Select-control>.Select-clear-zone:hover {
    color: #333;
}

.btn i,
.btn-sm,
.btn-group-sm>.btn {
    border-radius: 0px !important;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: 0px !important;
}

.table-hover>tbody>tr:hover table-hover>tbody>tr {
    background-color: #edf9ff;
}

.table-hover>tbody>tr:hover {
    background-color: #edf9ff;
    cursor: pointer
}

.row-selected {
    background-color: #caedff !important;
}

label,
table {
    font-size: 12px;
}

.nav-tabs.nav-justified>li>a {
    border-radius: 0px !important;
}

.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:hover,
.nav-tabs.nav-justified>.active>a:focus {
    border-top: 4px solid #0af;
}

.bootstrap-grid-no-padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.bootstrap-grid-sm-padding {
    padding-right: 5px !important;
    padding-left: 5px !important;
    padding-bottom: 10px;
}

.bootstrap-grid-md-padding {
    padding-right: 10px !important;
    padding-left: 10px !important;
}

.tag-label {
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-right: 5px;
    font-size: smaller;
}

.tab-content-container-middle-card {
    padding: 15px 15px 0px 15px;
    background-color: grey;
    text-align: center;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
}

.tab-content-container-middle-card-no-content {
    padding: 15%;
    text-align: center;
    border-right: 1px solid #ececec;
    border-left: 1px solid #ececec;
}

.tab-no-content-message {
    color: white;
}

.contentClassName {
    top: 0px !important;
}

.input-group.date>input[type=text] {
    z-index: 0 !important;
}

.multi-select-widthauto .Select-menu-outer {
    z-index: 999 !important;
    width: auto !important;
    font-size: 11px !important;
}

.multi-select-width100 .Select-menu-outer {
    width: 100% !important;
    font-size: 11px !important;
    min-width: 100px !important;
}

.drop-up .Select-menu-outer {
    top: auto;
    bottom: 100%;
}

.bg-transparent {
    background-color: transparent !important;
}

.svg-shadow {
    filter: drop-shadow(10px 10px 5px #DADADA);
}

.sort-column[title='Status'],
.sort-column[title='Tax Year'],
.sort-column[data-field='documentStatus'],
.sort-column[data-field='signatureStatus'] {
    overflow: inherit !important;
}

.react-bs-container-header {
    overflow: inherit !important;
}

.Select-multi-value-wrapper span:not(:first-child) {
    display: none;
}

.textalign-right {
    text-align: right;
}

.upload-doc-modal .prosystem-upload-doc-body {
    overflow: auto;
}


/* SF */

.sf-container {
    width: 90%;
    margin-top: 15px;
}

.sf-progressbar {
    counter-reset: step;
}

.sf-progressbar li {
    list-style: none;
    display: inline-block;
    width: 25%;
    position: relative;
    text-align: center;
    cursor: pointer;
    vertical-align: top;
}

.sf-progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 25px;
    height: 25px;
    line-height: 20px;
    border: 2px solid #ddd;
    border-radius: 999px;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    background-color: #fff;
}

.sf-progressbar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #ddd;
    top: 11px;
    left: -50%;
    z-index: -1;
}

.sf-progressbar li:first-child:after {
    content: none;
}

.sf-progressbar li.sfstep-active:before {
    border-color: #0973BA;
    color: #0973BA;
}

.sf-progressbar li.sfstep-active {
    font-weight: 700;
}

.sf-progressbar li.sfstep-completed:before {
    border-color: #0973BA;
    content: url('../../images/CheckIcon.svg');
    font-size: 15px;
    font-weight: 700;
    background-color: #0973BA;
    color: #FFFFFF;
    padding-top: 1px;
}

.sf-progressbar li.sfstep-completed+li:after {
    background-color: #0973BA;
}

.card-footer .btn+.btn {
    margin-left: 10px;
}

.sf-panel-header {
    background-color: white;
    position: sticky;
    top: 0px;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: white;
    /* font-size: 3rem; */
    /* position: relative; */
    z-index: 10;
    height: 100px;
}

.sf-panel-footer {
    position: absolute;
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: right;
    -ms-flex-align: center;
    align-items: right;
    -ms-flex-pack: center;
    justify-content: flex-end;
    background-color: white;
    background-color: white;
    text-align: right;
    margin: -1px;
}

.text-center-align {
    text-align: center !important;
}

.text-left-align {
    text-align: left !important;
}

#sfUploadDocumentTable .react-bs-table-container .react-bs-table .react-bs-container-body {
    overflow: inherit;
}

.recipients-body {
    max-height: 260px;
    height: auto;
}

.recipients-body::-webkit-scrollbar {
    width: 6px;
    background: #F0F0F0;
    border-radius: 25px;
}

.recipients-body::-webkit-scrollbar-thumb {
    background: #0973BA;
    border-radius: 25px;
}

.recipients-body::-webkit-scrollbar-thumb:hover {
    background: #A8A8A8;
}

.sfPanel-body {
    position: relative;
    overflow-x: hidden;
    overflow: auto;
    height: calc(100vh - 260px);
}

.custom-sfPanel-body {
    position: static;
    overflow-x: hidden;
    overflow: initial;
    height: calc(100vh - 260px);
    padding: 0;
}

.custom-sfPanel-body .tab-content-container {
    height: 100%;
}

.custom-sfPanel-body .tab-content-container-left-card {
    height: 100%;
}

.custom-sfPanel-body .tab-content-container-middle-card {
    height: 100%;
}

.custom-sfPanel-body .tab-content-container-right-card {
    height: 100%;
}

.custom-sfPanel-body .tab-content-container .pr-right-pannel,
.custom-sfPanel-body .tab-content-container .pr-left-pannel,
.custom-sfPanel-body .tab-content-container .pr-center-pannel {
    position: relative;
    height: 100%;
}

.custom-sfPanel-body .scroll {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
}

.custom-sfPanel-body .box {
    height: 320px;
    display: block;
    background: #CCC;
    margin-bottom: 10px;
}

.accordian-panel .card .card-header .card-title .accordion-toggle:after {
    /* symbol for "opening" panels */
    font-style: normal;
    font-family: 'Glyphicons Halflings';
    /* essential for enabling glyphicon */
    content: "\e114";
    /* adjust as needed, taken from bootstrap.css */
    float: right;
    /* adjust as needed */
    color: grey;
    /* adjust as needed */
    font-style: normal;
}

.accordian-panel .card .card-header .card-title .accordion-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    font-style: normal;
    font-family: 'Glyphicons Halflings';
    /* essential for enabling glyphicon */
    content: "\e080";
    /* adjust as needed, taken from bootstrap.css */
    font-style: normal;
}

.sf-receipient-signer-sign-highlight {
    -webkit-box-shadow: 0px 1px 15px -3px #542d87;
    -moz-box-shadow: 0px 1px 15px -3px #542d87;
    box-shadow: 0px 1px 15px -3px #542d87;
}

.sf-sender-signer-sign-highlight {
    -webkit-box-shadow: 0px 1px 12px -3px #666666;
    -moz-box-shadow: 0px 1px 12px -3px #666666;
    box-shadow: 0px 1px 12px -3px #666666;
}

.mar-B0 {
    margin-bottom: 0px !important;
}

.bookmarksPane .accordian-panel .panel {
    border-radius: 0px !important;
    margin-bottom: 0px !important;
}

#mailMergeUploadCsvTable {
    padding-top: 7%;
}

#mailMergeUploadCsvTable .react-bs-table-container .react-bs-table .react-bs-container-body {
    overflow: inherit;
}

#mailMergeUploadCsvTable .react-bs-container-header.table-header-wrapper {
    display: none;
}

.invalidMailMergeCellValue {
    color: red;
    cursor: pointer;
}

#div-validation-btn {
    height: 29vh;
    background-color: lightgrey;
    text-align: center;
    padding-top: 8vh;
}

#div-validation-btn div {
    margin-top: 10px;
}

.mailmerge-custom-field {
    border: none;
}

.mailmerge-custom-field-tag {
    background-color: #66afe930;
    border-radius: 5px;
    border: 1px solid #3791d8;
    color: black;
    display: inline-block;
    font-family: sans-serif;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px;
    cursor: pointer;
}

.mailmerge-custom-field-tag-remove {
    color: #274c96;
    padding-left: 5px;
    cursor: pointer;
}

.custom-field-highlight {
    border: 1px #666 solid;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
    -moz-box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
    box-shadow: 0px 0px 10px 5px rgba(209, 196, 8, 1);
}

#pdfpreview .modal-content {
    display: inline-block;
}

.csv-table-td-error {
    outline-color: red;
    outline-style: solid;
    outline-width: 3px;
    outline-offset: -1px;
}

.react-bs-table .table-bordered>tbody>tr>td.csv-table-td-error {
    outline: 3px solid red !important;
    outline-offset: -1px;
}

.react-bs-table .table-bordered>tbody>tr>td.csv-table-td-warning {
    outline: 3px solid orange !important;
    outline-offset: -1px;
}

.margin-tbl-20 {
    margin: 20px 0 20px 20px;
}

.margin-top-10 {
    margin-top: 10px;
}

.beforeContent {
    position: relative;
}

.beforeContent::before {
    content: '-';
    position: absolute;
    left: -10px;
}

@media screen and (min-width: 1360px) {
    #ddlUploadSFDocument .Select-arrow-zone {
        width: 15px;
    }
}

.Select-control .Select-arrow-zone {
    padding-right: 0px !important;
}

.fa-btn-icon {
    padding: 4px 0;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, .5);
    font-size: 12px;
}

.add-customauthority-btn {
    padding: 6px;
    min-width: 30px;
}

div.showbulkalert {
    position: relative;
    display: inline-block;
    left: 34%;
    z-index: 10;
    padding: 0px 0px;
    clear: both;
}

.multi-select-control .Select-menu-outer {
    width: 100%;
    font-size: 11px;
}

#datepicker_input div {
    min-width: 0px;
}

#datepicker_input div .react-datepicker {
    top: 100%;
}

.calendarContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%
}

.calendarInput {
    position: absolute;
    z-index: 1000;
    top: 30px;
}

.calendarClass {
    min-width: 0px;
    display: inline-block;
    position: relative;
    width: 100%
}

.inputClass {
    width: 100%;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    font-size: 12px;
    padding-left: 6px
}

.minusClass {
    color: #D3D3D3;
    position: absolute;
    top: 0%;
    right: 0%;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0px 3px 3px 0px;
    height: 97%;
    font-family: monospace
}

.minusClass:hover {
    cursor: pointer;
    color: #999999;
}

#dateId {
    width: 310px;
    max-width: fit-content;
    font-size: 14px;
    border: 1px solid #898D91;
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.15));
    border-radius: 4px 0px 4px 4px;
    font-size: 14px;
    margin-top: 7px;
}

#dateId .react-calendar__navigation__arrow {
    font-size: 25px;
    color: #898D91;
}

#dateId .react-calendar__navigation {
    margin-bottom: 0;
}

#dateId .react-calendar__month-view__weekdays {
    text-transform: capitalize;
    font-weight: normal;
}

#dateId .react-calendar__month-view__weekdays__weekday abbr[title] {
    text-decoration: none;
    cursor: default;
}

#dateId .react-calendar {
    padding: 7px 7px 0px 7px;
    border-bottom: none;
}

#dateId .react-calendar__viewContainer {
    border-bottom: 1px solid #DEE2E6;
    border-top: 1px solid #DEE2E6;
}

#dateId .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}

#dateId .react-calendar__tile--active {
    background: #05386B !important;
    color: white !important;
}

#dateId .react-calendar__tile:enabled:hover {
    background-color: #A6A9AC !important;
}

#dateId .react-calendar__tile:disabled {
    background-color: #f0f0f0 !important;
    color: #C0C0C0;
}

#dateId .react-calendar__tile--now {
    background: none;
}

#dateId .react-calendar__month-view__days__day--weekend {
    color: black;
}

#dateId .react-calendar__tile {
    border-radius: 2px;
}

#dateId .calendarFooter {
    background: white;
    font-size: 14px;
    color: #05386B;
    height: 42px;
    width: 100%;
    border: 1px solid #898D91;
    border-top: none;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

#dateId .calendarFooter.disabled span {
    color: #898D91;
    cursor: not-allowed;
}

#dateId .calendarFooter span {
    cursor: pointer;
}

.tab-content-container-right-card .pr-left-pannel .scroll .accordion .card-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0;
}

.tab-content-container-right-card .pr-left-pannel .scroll .accordion .card-body .form-group .form-label {
    margin-bottom: 0;
    word-wrap: normal;
}

.react-bs-table-container .react-bs-table .react-bs-container-body td .button-group button i,
.react-bs-table-container .react-bs-table .react-bs-container-body .button-cell button i {
    padding: 4px;
}

.btn-group-vertical {
    display: inline-flex !important;
}

.react-bs-table-container .react-bs-table .react-bs-container-body td .button-group .dropdown-toggle::after,
.app-header-menu-item .dropdown-toggle::after {
    display: none;
}


/*---------------App Header Start-------------*/

.change-password-modal .modal-content,
.logout-confirmation .modal-content,
.header-my-download .modal-content,
.header-my-account-modal .modal-content {
    width: 600px !important;
    margin: auto
}

.report-problem-modal .modal-content {
    margin: auto
}

.my-login-history .modal-content {
    width: 900px !important;
    margin: auto
}

.my-settings .modal-content {
    width: 700px !important;
    margin: auto
}

.my-settings .modal-body,
.modal-content {
    overflow: visible;
}

.app-header-menu-item ul li a {
    text-decoration: none;
}

.app-header-menu-item ul li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}


/*---------------App Header Stop--------------*/

.modal-content {
    font-size: 16px;
    border-radius: 4.8px !important;
}


/*---------------Pagination Start----------------*/

.page-item.active .page-link {
    background-color: #0973BA;
    border-color: #0973BA;
}

a {
    color: #0973BA;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.15;
    color: #0973BA;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}

.react-bs-table-pagination p {
    font-size: 14px;
}


/*---------------Pagination End----------------*/


/*---------------Reports Start--------------*/

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu {
    position: fixed !IMPORTANT;
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu .dropdown-submenu .dropdown-menu {
    position: absolute !IMPORTANT;
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li {
    padding-top: 2px;
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li:hover {
    background-color: #C4C6C8;
}

.react-bs-container-body .button-cell .button-group .btn-group-vertical .dropdown-menu li a {
    color: black;
    font-size: 14px;
    padding-right: 5px;
    text-decoration: none;
}


/*---------------Reports Start--------------*/

.tab-content-container-right-card .pr-left-pannel .scroll .accordion .card-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0;
}

.tab-content-container-right-card .pr-left-pannel .scroll .accordion .card-body .form-group .form-label {
    margin-bottom: 0;
    word-wrap: normal;
}

.last-login-modal .modal-content {
    width: 600px !important;
    margin: auto
}

.automated-reminder-row {
    font-size: 14px;
    align-items: center;
    margin-right: 0px;
}

.card .card-body .tab-content-container .tab-content-container-middle-card .btn-toolbar {
    display: block;
}

#signature.card-footer {
    padding: 0.2rem 1.25rem !important;
}

#signature.card-footer .btn {
    margin-top: 5px;
}

.padB5 {
    padding-bottom: 5px;
}

.user-warning-modal .modal-dialog {
    width: 600px;
}

.recipient-edit-btn {
    width: 75px;
}

.recipient-msg-subject {
    margin-bottom: 10px;
}

.recipient-msg-subject input {
    font-size: 14px;
    color: #1C2B36;
}

.recipient-msg-txt-area {
    height: 300px;
    padding-left: 5px;
    margin-top: 8px;
}

.invalid-sender {
    color: red !important;
}

#divLoading {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /*background-color: rgba(255,255,255,0.7);*/
    z-index: 9999;
    display: none;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#divLoading::after {
    content: '';
    display: block;
    position: absolute;
    left: 49%;
    top: 45%;
    width: 40px;
    height: 40px;
    border: 4px solid #eee;
    border-top-color: #07d;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

.loader-text {
    text-align: center;
    /*background-color: rgba(255,255,255,0.7);*/
    font-weight: bold;
    font-size: 100%;
    margin-top: 25%;
}

.user-autologout-modal-title {
    color: rgb(32, 37, 41);
    margin: 0px;
    font-weight: 600;
    font-size: 20px;
    font-family: Mulish, sans-serif;
    display: inline-block;
}

.user-autologout-modal-content {
    margin-left: 7px;
    font-size: 1rem;
    font-family: Mulish, sans-serif;
}

.user-autologout-modal-footer-btn {
    font-size: 16px;
    padding: 5px 13px 8px 13px;
    box-shadow: none;
    border-radius: 0.8px !important;
    background: #0973ba;
    color: white;
}

.user-autologout-modal .modal-dialog {
    max-width: 500px;
}

.user-autologout-modal-footer-btn:link {
    background: #0973ba;
    color: white;
}

.user-autologout-modal .modal-content {
    border-radius: 6px !important;
    box-shadow: none !important;
}

.user-autologout-modal-footer-btn:visited {
    background: #0973ba;
    color: white;
}

.user-autologout-modal-footer-btn:hover {
    background: #0973ba;
    color: white;
}

.user-autologout-modal-footer-btn:active {
    background: #0973ba;
    color: white;
}

#ssuite-layout-wrapper .side-menu-container * {
    font-family: 'Mulish' !important;
}

#ssuite-layout-wrapper header * {
    font-family: "Mulish", sans-serif !important;
}

.btn .btn-white .header-action {
    background: none;
}

.report-header-width {
    position: relative;
    width: 76%;
    z-index: 1000;
}

.header-action {
    background: white;
    padding: 6px 9px 6px 9px;
    font-size: 16px;
    box-shadow: none !important;
    border-color: #6B7075;
    border-radius: 2px !important;
}

.header-action span {
    padding-left: 5px;
    font-family: 'Mulish';
}

.header-filter {
    margin-top: -64px;
    padding-bottom: 92px;
}

.header-filter-icon {
    font-size: 16px;
    background-color: white;
    height: 38px;
    border: 1px solid #6B7075;
    color: #05386B;
    background: white;
    border-width: 1px;
    border-radius: 2px !important;
}

.header-filter-button-size {
    width: 38px;
    height: 38px;
}

.filter-dropdown-text {
    font-size: 16px;
}

.report-header-border {
    border-top: hidden;
    border-bottom: 2px solid #202428;
}

.table-header-font {
    font-size: 16px;
}

.report-table-container table * {
    font-size: 16px;
    line-height: normal;
}

.report-signature-status {
    font-size: 14px;
    padding: 0px 5px 1px 5px;
}

.signature-status {
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    width: fit-content;
    max-width: 183px;
}

.signature-status-cd {
    background-color: #F7E8F0;
    color: #8B1655;
    border: 1px solid #8B1655;
}

.signature-status-partially-signed {
    background-color: #FBF0E7;
    color: #A9570F;
    border: 1px solid #A9570F;
}

.signature-status-out-for-sig {
    background-color: #E6F1F8;
    color: #075C95;
    border: 1px solid #075C95;
}

.signature-status-e-signed {
    background-color: #F0F4EC;
    color: #47682D;
    border: 1px solid #47682D;
}

.action-dropdown-btn {
    border: hidden;
    box-shadow: none;
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 15px;
    padding-right: 19px;
    background: none;
    color: black;
    height: 32px;
    width: 100%;
    text-align: left;
}

#delivered-return-dropdown>.dropdown-menu {
    padding-left: 0px;
}

.dropdown-menu .show .dropdown-menu-right {
    padding-left: 0px !important;
}

.table-column-header {
    font-weight: normal;
}

.Select-multi-value-wrapper .Select-placeholder {
    padding-right: 25px;
    padding-top: 2px;
}

.text-field-header .Select--multi .Select-multi-value-wrapper {
    padding-top: 2px;
}

.Select-menu-outer .Select-menu div {
    line-height: 26px;
    padding-left: 12px !important;
    width: 100%;
    padding-right: 15px !important;
}

.DefaultDownloadOptions .Select-menu div {
    line-height: 24px !important;
}

.caret-position {
    margin-bottom: 9px;
    margin-left: 6px;
}

thead input.filter.text-filter.form-control::placeholder {
    font-style: normal !important;
}

thead input.filter.text-filter.form-control::-webkit-input-placeholder {
    font-style: normal !important;
}

thead .select-filter.placeholder-selected {
    font-style: normal !important;
}

#datepicker_input .inputClass.form-control {
    padding-right: 20px;
}

.table-reports-document-note {
    height: 18px;
}

.table-reports-document-note p {
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.custom-alert-warning {
    color: black;
    background: linear-gradient(90deg, #0973BA 4px, #E6F1F8 5px);
    border-color: #E6F1F8;
    padding: 6px 5px 6px 5px;
    width: fit-content;
    border-radius: 4px;
}

.report-table-container .header-text>span {
    font-weight: normal;
}

.bootbox .modal-dialog-centered {
    place-content: center;
}

.send-reminder-modal {
    font-size: 16px;
}

.send-reminder-modal nav a.nav-link.active {
    color: #0973BA;
    font-weight: 700;
    background-color: transparent !important;
    border: 0px !important;
    border-bottom: 3px solid !important;
    padding: 8px 16px;
}

.send-reminder-modal nav a[aria-selected=false] {
    color: #212529;
    font-weight: 400;
    border: 0px !important;
    background-color: transparent !important;
}

.send-reminder-modal .custom-alert-warning {
    color: black;
    background: linear-gradient(90deg, #0973BA 4px, #E6F1F8 5px);
    padding-top: 15px;
    align-items: center;
    height: 70px;
    border-radius: 4px;
}

.send-reminder-modal .card-body {
    padding: 1.25rem 0px 1.25rem 5px;
}

.view-access-code-modal .custom-alert-warning {
    align-items: center;
    padding: 14px;
    margin-bottom: 19px;
    width: 99%;
}

.modal-scrollable {
    max-height: 435px;
    overflow: auto;
}

.signed-Details-modal .signerEmailRow,
.signed-Details-modal .signerNameRow,
.recycle-signed-Details-modal .signerEmailRow {
    display: flex;
    max-width: 90%;
}

.step4-reminder .Select-control,
.send-reminder-modal .Select-control {
    display: flex;
    height: 29px;
    align-items: center;
    border-radius: 4px !important;
    height: 31px;
    padding-right: 9px;
}

.send-reminder-modal .Select-placeholder,
.step4-reminder .Select--single>.Select-control .Select-value,
.send-reminder-modal .Select--single>.Select-control .Select-value {
    top: -2px;
}

.step4-reminder .Select-control div[aria-owns],
.send-reminder-modal .Select-control div[aria-owns] {
    padding-left: 58px;
}

.signature-status-signed {
    background-color: #E9F3ED;
    color: #47682D;
    border: 1px solid #47682D;
    font-size: 14px;
    padding: 2px 15px 2px 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    width: fit-content;
}

.signature-status-not-signed {
    background-color: #FAEDEC;
    color: #8F342F;
    border: 1px solid #8F342F;
    font-size: 14px;
    padding: 2px 15px 2px 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    width: fit-content;
}

.signature-status-stamping-failed {
    background-color: #FAEDEC;
    color: #8F342F;
    border: 1px solid #8F342F;
    font-size: 14px;
    padding: 2px 15px 2px 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    display: inline-block;
    width: 100%;
}

.signature-status-delegated {
    background-color: #FBF0E7;
    color: #A9570F;
    border: 1px solid #A9570F;
    font-size: 14px;
    padding: 2px 15px 2px 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    width: fit-content;
}

.edit-client-info-modal .clientAuthenticaionContainer,
.edit-client-info-modal .clientAuthenticaionTitle {
    display: block;
    font-size: 16px;
    margin: 12px 1px;
}

.edit-client-info-modal .clientAuthenticaionTitle {
    padding-left: 15px;
}

.edit-client-info-modal .recipients-body {
    max-width: 100% !important;
    margin-left: 0px;
    max-height: none !important;
}

.edit-client-info-modal .sfPanel-body {
    height: auto !important;
    overflow: visible !important;
}

.edit-client-info-modal .Select-control {
    border-radius: 3.2px !important;
    text-overflow: ellipsis;
}

.edit-client-info-modal .form-control {
    border-radius: 2px !important;
    text-overflow: ellipsis;
    border: 1px solid #898D91 !important;
    height: 32px;
}

.edit-client-info-modal .editClientInformation .form-control {
    font-size: 14px;
    font-weight: 400;
}

.edit-client-info-modal .Select-placeholder,
.edit-client-info-modal .Select--single>.Select-control .Select-value {
    top: -2px;
    font-weight: 400;
}

.edit-client-info-modal .Select-placeholder,
.edit-client-info-modal .editClientInformation div input.form-control::placeholder {
    color: #565A5E !important;
}

.edit-client-info-modal .Select--single>.Select-control .Select-value,
.edit-client-info-modal .editClientInformation div input.form-control {
    color: #212529 !important;
}

.edit-client-info-modal input {
    border-radius: 3.2px !important;
}

.edit-client-info-modal .Select-control,
.edit-client-info-modal .Select-input {
    height: 31px;
}

.edit-client-info-modal .recipient-name {
    max-width: 189px;
    flex: 0 0 69%;
    padding: 0 0 0 7px;
}

.edit-client-info-modal .recipient-email {
    max-width: 220px;
    flex: 0 0 69%;
    margin: 0px;
    padding: 0 0 0 7px;
}

.edit-client-info-modal .authentication-options {
    padding-top: 7.5px;
    flex: 0 0 50%;
    max-width: 264px;
}

.edit-client-info-modal .recipient-type {
    max-width: 110px;
    padding: 0 0 0 7px;
}

.edit-client-info-modal .recipient-type .Select.has-value.is-clearable.Select--single>.Select-control .Select-value {
    padding-right: 20px;
}

.edit-client-info-modal .authentication-type {
    padding-top: 7.5px;
    flex: 0 0 50%;
    max-width: 240px;
    padding-right: 6px !important;
}

.edit-client-info-modal .modal-body {
    max-height: 565px;
    overflow-y: auto;
    padding-left: 0px !important;
}

.edit-client-info-modal .authentication-type .flag-dropdown.open .country-list {
    top: auto;
    bottom: 68%;
    width: 210px !important;
}

.edit-client-info-modal .authentication-type .authentication-answer {
    padding-top: 13px;
    margin-left: -265px;
    max-width: 529px;
    flex: 0 0 210%;
}

.edit-client-info-modal .authentication-type .authentication-answer input {
    border: 1px solid #898D91;
    font-size: 14px;
    font-weight: 400;
}

.bootbox .modal-dialog-centered {
    place-content: center;
}

.custom-column-filter-modal .modal-body .form-check-input {
    accent-color: #0973BA;
}

.custom-column-filter-modal .modal-body {
    accent-color: #0973BA;
}


/* ////////////////////////// Css only work in Firefox ////////////////////////// */

@-moz-document url-prefix() {
    .custom-scroll-bar {
        scrollbar-color: transparent transparent;
    }

    .dropDown-custom-scroll-bar .Select-menu-outer .Select-menu {
        scrollbar-color: transparent transparent;
    }

    .custom-scroll-bar:hover,
    .dropDown-custom-scroll-bar .Select-menu-outer .Select-menu:hover {
        scrollbar-color: #0973BA transparent;
    }
}


.custom-scroll-bar::-webkit-scrollbar,
.custom-scroll-bar::-webkit-scrollbar-thumb,
.dropDown-custom-scroll-bar .Select-menu-outer .Select-menu::-webkit-scrollbar,
.dropDown-custom-scroll-bar .Select-menu-outer .Select-menu::-webkit-scrollbar-thumb {
    display: none;
}

.custom-scroll-bar:hover::-webkit-scrollbar,
.dropDown-custom-scroll-bar .Select-menu-outer .Select-menu:hover::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
    background: #E9ECEF;
    display: block !important;
}

.custom-scroll-bar:hover::-webkit-scrollbar-thumb,
.dropDown-custom-scroll-bar .Select-menu-outer .Select-menu:hover::-webkit-scrollbar-thumb {
    background: #0973BA;
    border-radius: 61px !important;
    display: block !important;
}

.modal-footer .btn-danger {
    background-color: #CB4A43;
}

.report-problem-modal .section-header {
    font-weight: 700;
    font-size: 15px
}

.report-problem-modal .section-field {
    font-weight: 600;
    font-size: 14px;
}

.report-problem-modal .section-value {
    color: #6B7075;
}

.report-problem-modal .editable-section-value {
    position: relative;
}

.report-problem-modal .editable-section-value span {
    position: absolute;
    top: 51%;
    cursor: pointer;
    left: 97%;
    padding: 0px;
    transform: translateY(-60%);
}

.report-problem-modal .section-value input {
    border: 1px solid #898D91;
    border-radius: 3.2px !important;
    width: 196px;
    font-size: 14px;
    color: #212529;
    padding-left: 8px;
    padding-right: 20px;
    margin-left: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: 400;
}

.report-problem-modal textarea#report-problem-text.form-control::placeholder {
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    color: #898D91 !important;
}

.report-problem-modal .pad-b {
    padding-bottom: 8px;
}

#report-problem-text {
    border: 1px solid #A6A9AC;
}

.resend-access-link-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
    text-decoration: none !important;
}

.resend-access-link {
    text-decoration: underline;
    margin: 0px 20px 0px 3.8px;
    color: #05386B;
    cursor: pointer;
}

.copy-access-link {
    margin-left: 3.8px;
    color: #05386B;
    cursor: pointer;
}

.resend-access-link:hover {
    text-decoration: underline;
    color: #05386B;
}

.resend-access-link-modal .modal-body {
    min-height: 115px;
}

.set-access-modal .ddcontainerghost,
.sender-delegation-modal .ddcontainerghost {
    width: 100%;
    max-width: 17.2%;
}

.set-access-modal .ddcontainerghost .set-access-users:hover,
.sender-delegation-modal .ddcontainerghost .sender-delegation-users:hover {
    background-color: #6BABD6;
}

.set-access-modal .ddcontainersource .set-access-users:hover,
.sender-delegation-modal .ddcontainersource .sender-delegation-users:hover {
    background-color: #C5C5C5;
}

.set-access-modal .ddcontainerghost,
.sender-delegation-modal .ddcontainerghost {
    width: 100%;
    max-width: 17.2%;
}

.set-access-modal .activeSelected,
.sender-delegation-modal .activeSelected {
    background-color: #6BABD6 !important;
}

.modal-alert {
    margin-left: 14px !important;
    margin-right: 14px !important;
    color: black;
    background: linear-gradient(90deg, #0973BA 4px, #E6F1F8 5px);
    border-radius: 4px !important;
    height: 56px;
}

.set-access-users,
.sender-delegation-users {
    height: 33px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.set-access-users.odd,
.sender-delegation-users.odd {
    background: white;
}

.set-access-users.even,
.sender-delegation-users.even {
    background: #F2F2F2;
}

.ddcontainer {
    display: unset !important;
}

.resend-access-link-email {
    margin-right: 20px;
    color: #0973BA;
    font-weight: 700;
    width: 250px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: -6px;
}

.report-document-note-modal .modal-body i,
.mce-panel i {
    color: black;
}

.report-document-note-modal .modal-body {
    font-size: 16px !important;
    padding: 37px 30px 23px 30px !important;
}

.report-document-note-modal .modal-dialog {
    min-width: 650px;
}

.report-document-note-modal .modal-body>div>div>div>div>.mce-edit-area {
    min-height: 247px;
}

.report-document-note-modal .modal-body .mce-tinymce.mce-container.mce-panel {
    box-shadow: none;
    border-bottom: none;
}

.mce-active,
.mce-active:hover,
.mce-toolbar-grp button:hover {
    border: none;
    background: #DEE0E2 !important;
}

.mce-active>button>i {
    color: black !important;
}

.custom-column-filter-modal .modal-body * {
    font-size: 14px;
}

.toastify-container {
    width: 400px !important;
    margin-right: 9px;
    margin-top: 47px;
}

.toastify-body {
    border-radius: 4px !important;
    color: black;
}

.toastify-error {
    background: #FAEDEC !important;
    border-left: 5px solid #B8433C;
}

.toastify-warning {
    background: #FBF5EB !important;
    border-left: 5px solid #D69F38;
}

.toastify-success {
    background: #E9F3ED !important;
    border-left: 5px solid #1F8747;
}

.toastify-info {
    background: #E6F1F8 !important;
    border-left: 5px solid #0973BA;
}

.toastify-icon {
    align-self: center;
}

.toastify-icon.toastify-close svg:hover {
    fill: #3F3B3B;
}

.toastify-icon-pad {
    padding-right: 12px;
}

.toastify-text {
    font-size: 16px;
    padding-right: 14px;
}

.custom-curve-check-box {
    padding-left: 5px;
    margin-bottom: 1px;
}

.custom-curve-check-box.SignatureArchivecheckbox,
.custom-curve-check-box.SignatureRecyclecheckbox {
    margin-top: 1px;
}

.custom-curve-check-box .magic-checkbox+label:before,
.custom-curve-check-box .magic-checkbox-sm+label:before {
    height: 16px;
    width: 16px;
    border-radius: 4px;
}

.custom-curve-check-box .magic-checkbox+label:before {
    border-radius: 4.4px;
}

.custom-curve-check-box .magic-checkbox-sm+label:after,
.custom-curve-check-box .magic-checkbox+label:after {
    top: -2px !important;
    left: 4px !important;
    height: 8px !important;
    border-width: 2.5px;
}

.default-access-options .custom-curve-check-box .magic-checkbox+label:after {
    top: 7.4px !important;
    left: 5.4px !important;
    border-width: 2.8px;
}

.custom-curve-check-box .magic-checkbox-sm-indeterminate+label:after {
    top: -1px !important;
    left: 6.1px !important;
    height: 8px !important;
}

.custom-curve-check-box .magic-checkbox-sm:checked+label:before,
.custom-curve-check-box .magic-checkbox:checked+label:before {
    background: #0973BA;
}

.custom-column-filter-modal .custom-curve-check-box {
    margin-top: 1px;
}

.custom-column-filter-modal .custom-curve-check-box .form-check {
    padding-left: unset;
}

.custom-column-filter-modal .custom-curve-check-box .magic-checkbox:checked[disabled]+label:before {
    background: #84B9DC !important;
}

.custom-column-filter-modal .custom-curve-check-box .magic-checkbox+label:after {
    top: 3px !important;
    left: 5px !important;
}

.custom-column-filter-modal .column-disabled {
    color: #212529;
}

.text-field-header .form-control,
.text-field-header .react-bs-table-bordered,
.text-field-header .btn,
.text-field-header .Select-control,
.text-field-header .is-focused .Select-control {
    border: 1px solid #898D91;
    border-radius: 3.2px !important;
    background: rgba(196, 198, 200, 0.2) !important;
}

.text-field-header .is-focused .Select-control .Select-multi-value-wrapper,
.text-field-header .is-focused .Select-control .Select-multi-value-wrapper .Select-input {
    background: unset;
}

.text-field-header {
    padding-bottom: 12px !important;
}

.text-field-header div>div>.Select-control {
    height: calc(1.5em + .75rem + 2px) !important;
}

.bootbox .permanentDeleteModel .modal-content {
    max-width: 545px !important;
}

.send-reminder-modal .modal-body nav a {
    width: 50% !important;
}

.select-reminder-control .Select-arrow-zone {
    margin-left: 7px;
}

.select-reminder-control .Select-menu-outer .Select-menu {
    border-radius: 4px;
}

.signatureflow-report #delivered-return-dropdown+.dropdown-menu {
    position: absolute !important;
}

.security-settings-container .columnFilterSlider,
.automated-reminder-row .columnFilterSlider,
.mandate-client-id .columnFilterSlider {
    width: 34px;
    height: 16px;
    border-radius: 34px;
}

.security-settings-container .columnFilterSlider:before,
.automated-reminder-row .columnFilterSlider:before,
.mandate-client-id .columnFilterSlider:before {
    width: 12px;
    height: 12px;
    transform: translate(2px, 1px);
    background-color: #BFBFBF;
}

.security-settings-container input:checked+.columnFilterSlider:before,
.automated-reminder-row input:checked+.columnFilterSlider:before,
.mandate-client-id input:checked+.columnFilterSlider:before {
    transform: translate(19px, 1px);
    box-shadow: 7px 6px 25px white inset;
}

.security-settings-container input[type="checkbox"]:checked+.columnFilterSlider,
.automated-reminder-row input[type="checkbox"]:checked+.columnFilterSlider,
.mandate-client-id input[type="checkbox"]:checked+.columnFilterSlider {
    background: #0973BA;
}

.security-settings-container .columnFilterSlider,
.automated-reminder-row .columnFilterSlider,
.mandate-client-id .columnFilterSlider {
    background-color: #FFFFFF;
    box-shadow: unset !important;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.header-text .Select--multi .Select-menu-outer .Select-menu div {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 2.5px;
}

.saved-message .magic-checkbox+label:before,
.header-text .custom-curve-check-box .magic-checkbox+label:before {
    top: 2px;
}

.saved-message .custom-curve-check-box .magic-checkbox+label:after,
.header-text .custom-curve-check-box .magic-checkbox+label:after {
    top: 5px !important;
    left: 5px !important;
}

.custom-single-select-container .Select-menu-outer {
    padding: 5px 0px 5px 0px;
}

.custom-single-select-container .Select-menu-outer .Select-menu>div:hover {
    background: #F2F2F2;
}

.custom-single-select-container .Select-menu-outer .Select-menu .selected {
    background: #C4C6C8 !important;
}

.sf-esign-details-modal td.react-bs-table-expand-cell {
    padding-left: 7px;
}

.settingsPage>h3 {
    margin-top: 12px;
    font-weight: 600;
}

.settingsPage .new-main-content {
    margin-top: 25px;
}

.settingsPage .new-main-content.Senderdelegation {
    margin-top: 33px;
}

.security-settings .new-main-content.default-access-options {
    margin-top: 25px;
}

.default-access-options input.magic-radio+label:before,
.Senderdelegation input.magic-radio+label:before,
.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection input.magic-radio+label:before {
    margin-top: 3px;
}

.default-access-options input.magic-radio+label.checked:before,
.Senderdelegation input.magic-radio+label.checked:before,
.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection input.magic-radio+label.checked:before {
    background: #0973BA;
    margin-top: 3px;
}

.default-access-options input.magic-radio+label.checked:after,
.Senderdelegation input.magic-radio+label.checked:after,
.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection input.magic-radio+label.checked:after {
    background: #FFFFFF;
    margin-top: 3px;
}

.default-access-options label,
.Senderdelegation label,
.automatedReminderSettings label {
    font-size: 16px;
}

.default-access-options>div,
.Senderdelegation>div {
    margin-top: 25px;
}

.new-main-content .magic-radio+label:before {
    border: 1.9px solid #D9D9D9;
}

.automatedReminderSettings .Select-control {
    display: flex;
    height: 29px;
    align-items: center;
    border-radius: 4px !important;
    height: 31px;
    padding-right: 9px;
    font-size: 14px;
}

.automatedReminderSettings .Select-placeholder,
.automatedReminderSettings .Select--single>.Select-control .Select-value {
    top: -2px;
}

.automatedReminderSettings .custom-curve-check-box {
    padding-left: 0;
}

.automatedReminderSettings .Select-control div[aria-owns] {
    padding-left: 58px;
}

.automatedReminderSettings .magic-checkbox+label:before,
.conditional-control-settings-panel .magic-checkbox+label:before {
    top: 5px
}

.automatedReminderSettings .magic-checkbox+label:after,
.conditional-control-settings-panel .magic-checkbox+label:after {
    top: 8px !important;
    left: 5px !important;
}

.automatedReminderSettings .Select-menu-outer .Select-menu div {
    line-height: 17px;
}

.sf-esign-details-modal td.react-bs-table-expand-cell {
    padding-left: 7px;
}

.modal-footer .text-right {
    margin-right: 0px;
    padding-right: 0px;
}

.modal-footer .custom-curve-check-box {
    padding-left: unset;
    margin-left: unset;
}

.modal-footer .custom-curve-check-box .form-check {
    padding-left: unset;
    margin-left: unset;
}

.modal-footer .custom-curve-check-box .magic-checkbox+label:after {
    top: 4px !important;
    left: 5px !important;
}

.modal-footer .magic-checkbox+label:before {
    top: 1.5px;
}

.document-type-modal .modal-body .Select-control,
.sigflow-settings-popup input,
.sigflow-settings-popup textarea {
    border-radius: 3.4px !important;
    border: 1px solid #898D91;
    color: black;
}

.blueModalHeader .modal-title {
    color: white;
    font-weight: 500;
}

.blueModalHeader .close span {
    color: white;
    text-shadow: none;
}

.fontSize14-all * {
    font-size: 14px;
}

.saved-message .custom-curve-check-box {
    padding-left: 0px;
}

.saved-message .custom-curve-check-box .form-check {
    padding-left: 0px;
}

.saved-message .magic-checkbox+label {
    padding-left: 24px;
}

#ddlUserChange {
    border: none;
}

.document-type-modal .modal-body .Select-control {
    border: 1px solid #898D91;
    border-radius: 3.4px !important;
}

.document-type-modal .modal-dialog {
    max-width: 711px;
}

.document-type-modal .modal-body {
    min-height: 312px;
}

.document-type-modal .modal-body .custom-curve-check-box {
    height: 100%;
    padding-top: 10px;
    padding-left: 28px;
}

.document-type-modal .modal-body .custom-curve-check-box .magic-checkbox+label:after {
    top: 4.5px !important;
    left: 5px !important;
}

.document-type-modal .modal-body .custom-curve-check-box .magic-checkbox+label:before {
    top: 2px;
}

.document-type-modal .modal-body .custom-alert-warning {
    padding-top: 16px;
    padding-bottom: 16px;
}

.custom-alert-warning-container {
    position: relative;
    display: inline-block;
    padding-left: 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    padding-right: 0px;
    max-width: 100%;
}

.document-type-modal .custom-alert-warning-container {
    margin-top: 22px;
    margin-bottom: 33px;
}

.document-type-modal .magic-checkbox+label {
    padding-left: 24px;
}

.custom-alert-warning-container .custom-alert-warning {
    padding-right: 0px;
    width: 100%;
}

.settings-add-btn {
    border: 1px solid #0973BA;
    background: white !important;
    border-radius: 2px !important;
    color: #0973BA !important;
    width: 98px;
    height: 31px;
    margin-top: 21px;
    padding: 5px;
    font-size: 14px;
}

.sigflow-settings-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
}

.settings-list-container {
    border: 1px solid #898D91 !important;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.075));
    border-radius: 0px 0px 2px 2px;
}

.settings-list-container .custom-list-group-item-singleline {
    border: unset;
    border-top: 1px solid #A6A9AC;
}

.document-type-container {
    margin-top: 33px !important;
    margin-bottom: 41.5px !important;
}

.text-message * {
    font-size: 14px;
}

.text-message .form-control {
    border: 1px solid #898D91 !important;
    border-radius: 3.2px !important;
}

.security-settings-container .custom-curve-check-box {
    padding-left: 0px;
    padding-top: 34px;
}

.security-settings-container .magic-checkbox+label:before {
    top: 2px;
}

.security-settings-container .custom-curve-check-box .magic-checkbox+label:after {
    top: 5px !important;
    left: 5px !important;
}

.custom-list-group-item-singleline.custom-active,
.custom-list-group-item-singleline.custom-active:hover {
    color: black;
    background-color: #CAEDFF;
}

.draft-action-button {
    padding: 4px 10px 2px 10px;
    margin-top: -5px;
    height: 30px;
}

.draft-dropdown-menu .dropdown-item:active,
.draft-dropdown-menu .dropdown-item:hover {
    background: #C4C6C8
}

.draft-dropdown-menu .dropdown-item {
    padding: 0px;
}

.file-upload-table-header {
    border-top: hidden;
    border-bottom: 2px solid #202428;
    font-size: 16px;
}

#sfUploadDocumentTable .progress {
    height: 10px !important;
    border-radius: 30px !important;
    width: 90%;
}

#sfUploadDocumentTable .delete-action-button {
    padding: 0px;
    font-weight: 400;
    font-size: 16px;
}

#sfUploadDocumentTable .file-upload-table-body {
    border-bottom: 1px solid #A6A9AC;
}

#sfUploadDocumentTable .datepicker-control-section input {
    border-radius: 3px !important;
    height: 31px;
    font-size: 14px;
    border: 1px solid #898D91;
    font-weight: 400;
}

#sfUploadDocumentTable .datepicker-control-section input [placeholder] {
    color: #898D91;
}

#sfUploadDocumentTable .datepicker-control-section input+.minusClass {
    right: 5%;
}

.upload-doc-modal .upload-drop-zone {
    border: 1px dashed #0973BA;
    float: left;
    border-radius: 2px;
    padding: 0px;
    min-height: 330px;
    height: calc(100vh - 345px);
    max-height: 543px;
}

.upload-doc-modal .upload-doc-header-action div .Select-control .Select-clear-zone,
.upload-doc-modal .upload-doc-header-action div .Select-control .Select-arrow-zone {
    padding-top: 2px;
    padding-bottom: 5px;
}

.upload-doc-modal .upload-doc-header-action input,
.upload-doc-modal .upload-doc-header-action div .Select-control {
    height: 38px;
    font-size: 16px;
    border-radius: 4px !important;
}

.upload-doc-modal .upload-doc-header-action input {
    padding: 6px 12px;
}

.upload-doc-modal .upload-doc-header-action input.show-validation-field {
    border: 1px solid #CC4A43 !important;
}

.upload-set-access-btn {
    background: white;
    padding: 6px 9px 6px 9px;
    font-size: 16px;
    box-shadow: none !important;
    border-color: #6B7075;
    background: none;
    border-radius: 2px !important;
    float: right;
    height: 38px;
    margin: 6px 0 0 0;
}

.upload-set-access-btn span {
    padding-left: 5px;
    font-family: 'Mulish';
}

.upload-doc-modal hr {
    border: 1px solid #C4C6C8;
    height: 38px;
    margin-bottom: 0px;
    margin-top: 19px;
}

.upload-doc-modal .show-validation-text {
    position: absolute;
    padding-left: 3px;
    padding-top: 2px;
    color: #CC4A43;
}

.upload-doc-modal .show-validation-field .Select-control {
    border: 1px solid #CC4A43 !important;
}

.qa-popup .col-md-12 {
    margin-bottom: 24px;
}

.qa-popup .form-control,
.qa-popup .Select-control {
    border-radius: 3px !important
}

.qa-popup .form-group {
    margin-bottom: 0px;
}

.qa-popup .modal-body {
    padding-bottom: 0px;
}

.qa-popup .Select-control .Select-arrow-zone {
    padding-right: 15px !important;
}

.draft-assign-to-modal .select-component-container {
    width: 366px !important;
    margin-bottom: 14px;
}

#assign-to-user-list .Select-control {
    border-radius: 3px !important;
    border: 1px #898D91 solid;
}

#assign-to-user-list .Select-multi-value-wrapper .Select-placeholder {
    padding-top: 0px;
}

#assign-to-user-list .Select-control * {
    font-size: 14px;
}

.draft-assign-to-modal .modal-alert {
    padding-bottom: 0px;
    padding-top: 6px;
    margin-left: 0px !important;
    margin-bottom: 25px;
    margin-top: 3px;
}

.draft-assign-to-modal .Select-multi-value-wrapper .Select-input {
    height: 31px;
}

.draft-assign-to-modal .Select-control {
    height: 31px;
}

.draft-assign-to-modal .option {
    line-height: 20px !important;
}

.borderRadius3 {
    border-radius: 3px !important;
}

.step-2,
.step-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.step-4>div {
    width: 75%;
    margin-left: 18%;
}

.step-2>div {
    width: 80%;
    margin-left: 13%;
}

.step-2 .Select-control,
.step-4 .form-control {
    border-radius: 3px !important;
}

.step-4 .form-control {
    font-size: 14px;
    height: 31px;
}

.step-4 .input-field {
    width: 155px;
    padding-right: 23px;
}

.step-2 .clientAuthenticaionTitle {
    font-weight: 600;
    font-size: 14px;
}

.step-2 .Select-input,
.step-2 .Select-control {
    height: 31px;
}

.step-2 .Select-input>input {
    padding-bottom: 6px;
}

.step-2 .Select-multi-value-wrapper .Select-placeholder {
    padding-top: 0px;
    color: #565A5E !important;
}

.step-2 .authentication-options {
    padding-left: 10px !important;
    padding-right: 12px !important;
}

.step-2 .Select-control .Select-arrow-zone {
    padding-right: 7px !important;
}

.step-2 .Select-clear-zone {
    padding-right: 2px !important;
}

.step-2 .recipient-email .Select-clear-zone,
.edit-client-info-modal .clientAuthenticaionContainer #id-recipients-body .Select-control .Select-clear-zone {
    padding-right: 7px !important;
}

.step-2 .recipient-email .Select-arrow-zone {
    display: none;
}

.edit-client-info-modal .clientAuthenticaionContainer #id-recipients-body .recipient-email .Select-control .Select-arrow-zone {
    display: none;
}

.step-2 .clientAuthenticaionContainer .recipients-body {
    padding-left: 0px !important;
}

.step-2 .clientAuthenticaionContainer {
    margin-bottom: 7px;
}

.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection {
    margin-top: 10px;
    margin-bottom: 15px;
    vertical-align: middle;
    font-weight: 400;
    font-size: 14px !important;
}

.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection label {
    font-size: 14px !important;
    padding-left: 24px !important;
    margin-bottom: 0px !important;
    font-weight: 400 !important;
    font-family: 'Mulish';
}

.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection label.sequence-info-icon {
    cursor: pointer;
    padding-left: 7px !important;
}

.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection label.sequence-info-icon .tooltip {
    z-index: 10;
    display: none;
    padding: 6px 5px !important;
    margin-top: 25px;
    line-height: 16px;
    position: absolute;
    margin-left: -198px;
    width: 400px;
}

.step-2 .clientAuthenticaionContainer .recipients-body .recipent-moveable-box .tooltip {
    z-index: 10;
    display: none;
    padding: 6px 5px !important;
    margin-top: 35px;
    line-height: 16px;
    position: absolute;
    margin-left: -45px;
    width: 260px;
}

.step-2 .clientAuthenticaionContainer .recipients-body .recipent-moveable-box .tooltip-inverted {
    z-index: 10;
    display: none;
    padding: 6px 5px !important;
    margin-top: -40px !important;
    line-height: 16px;
    position: absolute;
    margin-left: -45px;
    width: 260px;
}

.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection label.sequence-info-icon:hover .tooltip,
.step-2 .clientAuthenticaionContainer .recipients-body .recipent-moveable-box:hover .tooltip,
.step-2 .clientAuthenticaionContainer .recipients-body .recipent-moveable-box:hover .tooltip-inverted {
    display: inline;
    color: #ffffff;
    border: 1px solid #DCA;
    background: #444;
    -moz-border-radius: 11px;
    -webkit-border-radius: 11px;
    border-radius: 4px;
}

.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection label.sequence-info-icon .tooltip:after {
    position: absolute;
    display: block;
    content: "";
    border-style: solid;
    border-width: 8px;
    height: 0;
    width: 0;
    border-color: transparent transparent #444 transparent;
    top: -16px;
    left: 182px;
}

.step-2 .clientAuthenticaionContainer .recipients-body .recipent-moveable-box .tooltip:after {
    position: absolute;
    display: block;
    content: "";
    border-style: solid;
    border-width: 8px;
    height: 0;
    width: 0;
    border-color: transparent transparent #444 transparent;
    top: -16px;
    left: 20px;
}

.step-2 .clientAuthenticaionContainer .recipients-body .recipent-moveable-box .tooltip-inverted:after {
    position: absolute;
    display: block;
    content: "";
    border-style: solid;
    border-width: 8px;
    height: 0;
    width: 0;
    border-color: #444 transparent transparent transparent !important;
    top: 28px !important;
    left: 20px;
}

.step-2 .clientAuthenticaionContainer .recipients-body .is-disabled {
    background: #E9ECEF;
}

.edit-client-info-modal .recipients-body .recipent-moveable-box.is-disabled {
    border: 1px solid #C4C6C8 !important;
}

.edit-client-info-modal .recipients-body .recipent-moveable-box.is-disabled,
.edit-client-info-modal .recipients-body .recipent-moveable-box.is-disabled input {
    background: #F0F1F1 !important;
    pointer-events: none;
}

.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection input.magic-radio+label:after {
    top: 4.2px !important;
    left: 4px !important;
}

.step-2 .clientAuthenticaionContainer .clientAuthenticationSigningSelection input.magic-radio+label:before {
    width: 16px !important;
    height: 16px !important;
}

.step-2 .clientAuthenticaionContainer .recipient-name {
    padding-right: 0px !important;
    flex: 0 0 20%;
}


.step-2 .clientAuthenticaionContainer .recipient-email {
    padding-right: 0px !important;
    padding-left: 12px !important;
}

.step-2 .clientAuthenticaionContainer .recipient-type {
    padding-right: 0px !important;
    padding-left: 12px !important;
}

.step-2 .clientAuthenticaionContainer .recipient-name input::placeholder,
.step-2 .clientAuthenticaionContainer .recipient-email .Select-input input::placeholder {
    color: #565A5E !important;
    font-size: 14px;
    font-weight: 400;
}

.step-2 .clientAuthenticaionContainer .recipient-email .Select-input input::-webkit-input-placeholder {
    color: #565A5E !important;
}

#id-recipients-body .recipent-moveable-box {
    height: 33px;
    width: 50px;
    border: 1px solid #898D91;
    border-radius: 3.2px;
    padding-top: 4px;
    padding-left: 7px;
}

#id-recipients-body .recipent-moveable-box input {
    width: 21px;
    margin-left: 9px;
    border: none;
    color: #212529;
    font-size: 17px;
}

#id-recipients-body .recipent-moveable-box input[type="number"]::-webkit-inner-spin-button,
#id-recipients-body .recipent-moveable-box input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#id-recipients-body .recipent-moveable-box input[type="number"] {
    -moz-appearance: textfield;
}

.Select-control {
    border: 1px solid #898D91;
}

.step-2 .react-tel-input input,
.edit-client-info-modal .react-tel-input input {
    display: none;
}

.step-2 .react-tel-input .selected-flag,
.edit-client-info-modal .react-tel-input .selected-flag {
    padding-left: 2px;
    width: 100%;
}

.step-2 .react-tel-input .flag-dropdown ul,
.edit-client-info-modal .react-tel-input .flag-dropdown ul {
    margin-top: 2px;
}

.step-2 .react-tel-input .flag-dropdown.open .selected-flag,
.edit-client-info-modal .react-tel-input .flag-dropdown.open .selected-flag,
.step-2 .react-tel-input .selected-flag:hover,
.edit-client-info-modal .react-tel-input .selected-flag:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.step-2 .react-tel-input .flag-dropdown,
.edit-client-info-modal .react-tel-input .flag-dropdown {
    width: 37px;
    height: 31px;
}

.step-2 .phone-component input,
.edit-client-info-modal .phone-component input {
    height: 31px;
    padding-left: 40px;
}

.edit-client-info-modal .editClientInformation .editClientInformationText span {
    vertical-align: super !important;
    margin-left: 1px;
}

.step-2 .authentication-type .qa-text {
    cursor: pointer;
    color: #0973BA;
    text-decoration: underline;
    height: 39px;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.footer-back-btn {
    color: #0973BA;
    position: absolute;
    left: 0.3%;
    border: 1px solid #0973BA;
    border-radius: 2px;
    background-color: transparent;
    padding-left: 1px !important;
}

.footer-back-btn>svg {
    margin-bottom: 3px;
}

.tool-tip-popup .required-checkbox {
    position: absolute;
    left: 2%;
    user-select: none;
}

.tool-tip-popup .display-checkBox label::before {
    height: 16px;
    width: 16px;
    border-radius: 4px;
    top: 2.5px;
}

.tool-tip-popup .display-checkBox label {
    font-size: 14px;
    padding-left: 21px;
    color: #212529;
}

.tool-tip-popup .display-checkBox label::after {
    top: 4px !important;
    left: 5px !important;
    height: 9px !important;
}

.tool-tip-popup .form-group>textarea {
    resize: none;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #898D91;
}

.signature-control-panel,
.conditional-control-panel {
    border-bottom: 0px;
    border-top: 0px;
}

.conditional-control-panel {
    position: relative;
}

.signature-control-panel .card-body {
    padding-top: 10px;
}

.signature-control-panel .form-horizontal .Select-control {
    border-radius: 4px !important;
}

.signature-control-panel .Select-control .Select-arrow-zone {
    padding-right: 15px !important;
}

.signature-control-panel .signature-controls div:nth-child(3),
.signature-control-panel .signature-controls div:nth-child(6) {
    padding-bottom: 15px;
    border-bottom: 1px solid #C4C6C8;
}

.footer-next-btn {
    background-color: #0973BA !important;
    color: #FFFFFF;
    border-radius: 2px;
}

.footer-cancel-btn,
.footer-save-and-close-btn {
    background-color: #FFFFFF;
    color: #0973BA;
    border: 1px solid #0973BA;
    border-radius: 2px;
}

.footer-save-and-close-btn:hover,
.footer-back-btn:hover {
    color: #0973BA;
}

.footer-exit-without-saving-btn {
    text-decoration: underline;
    color: #212529;
}

.footer-exit-without-saving-btn:hover {
    color: #212529;
}

.footer-exit-without-saving-btn:disabled {
    pointer-events: unset;
}

.step-4 .input-clear-icon {
    transform: translate(-21px, 5px);
    height: 21px;
}

.step-4 .show-validation-text {
    position: absolute;
    color: #CC4A43;
}

.step-4 .show-validation-text.custom-transform {
    transform: translateY(32px);
}

.step-4 .show-validation-field {
    border: 1px solid #CC4A43 !important;
}

.step-4 .reviewFilesActions button {
    padding-left: 0px;
    color: #05386B;
    font-size: 16px;
    height: 31px;
    padding-top: 3px !important;
}

.step-4 .reviewFilesActions button[disabled],
#sfUploadDocumentTable .delete-action-button[disabled] {
    font-weight: 200;
    color: #C4C6C8 !important;
}

.step-4 .reviewFilesActions hr {
    height: 16px;
    border: 1px solid #C4C6C8;
    margin-top: 5px;
    margin-bottom: 2px;
    margin-left: -5px;
    margin-right: 5px;
}

.step-4 .control-pane {
    width: 80%;
}

.step-4 .reviewDocumentType .Select-control {
    height: 31px;
    border-radius: 3px !important;
}

.step-4 .reviewDocumentType .Select-value {
    line-height: 30px;
    padding-right: 12px;
}

.step-4 .reviewDocumentType .Select-input {
    display: none !important;
}

.step4-reminder .Select-control {
    margin-top: 4px;
}

.step4-reminder .Select {
    margin-left: 13px;
}

.step4-reminder .automated-reminder-row {
    width: 47px;
}

.step-4 .reviewFileTable {
    overflow-y: auto;
    height: 234px;
}

.step-4 .reviewFileTable .Select-menu-outer .Select-menu div {
    line-height: 13px;
}

.step-4 .reviewFileTable .Select-menu-outer .Select-menu {
    max-height: 162px;
}

.step-4 .reviewFileTable {
    scrollbar-color: transparent transparent;
}

.step-4 .reviewFileTable:hover {
    scrollbar-color: #0973BA transparent;
}

.step-4 .reviewFileTable::-webkit-scrollbar,
.step-4 .reviewFileTable::-webkit-scrollbar-thumb {
    display: none;
}

.step-4 .reviewFileTable:hover::-webkit-scrollbar {
    width: 5px;
    background: #E9ECEF;
    display: block !important;
}

.step-4 .reviewFileTable:hover::-webkit-scrollbar-thumb {
    background: #0973BA;
    border-radius: 61px;
    display: block !important;
}

.pdfViewer .document-control span {
    margin: 6%;
}

.pdfViewer .document-control {
    z-index: 1 !important;
}

.step-4 .reviewOfficeLocationDropdown .Select-control {
    height: 31px;
    border-radius: 3px !important;
    width: 155px;
    border: 1px solid #898D91;
}

.step-4 .defaultSigningEvents .Select-control {
    height: 31px;
    border-radius: 3px !important;
    width: 170px;
    border: 1px solid #898D91;
}

.step-4 .defaultSigningEvents .Select-input,
.step-4 .reviewOfficeLocationDropdown .Select-input {
    height: 31px;
}

.step-4 .defaultSigningEvents .Select,
.step-4 .reviewOfficeLocationDropdown .Select {
    padding-top: 3px;
}

.step-4 .defaultSigningEvents .Select-multi-value-wrapper .Select-placeholder,
.step-4 .reviewOfficeLocationDropdown .Select-multi-value-wrapper .Select-placeholder {
    padding-top: 0px !important;
}

#id-recipients-body .active {
    background-color: transparent;
}

#id-recipients-body .country-list .country.highlight {
    background-color: #f1f1f1 !important;
}

#id-recipients-body .authentication-type .react-tel-input .selected-flag .flag {
    left: 12%;
}

.step-4 .defaultSigningEvents .Select-menu-outer .Select-menu div,
.step-4 .reviewOfficeLocationDropdown .Select-menu-outer .Select-menu div {
    line-height: 21px;
}

.step-4 .defaultSigningEvents .Select-menu,
.step-4 .reviewOfficeLocationDropdown .Select-menu {
    max-height: 180px !important;
}

.featureDisabled-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 108px);
}

.featureDisabled-body h2 {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    margin-top: 40px;
}

.featureDisabled-body-text {
    font-size: 20px;
    font-weight: 400;
    white-space: pre;
}

.featureDisabled-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.featureDisabled-btn>button {
    font-size: 16px;
    font-weight: 400;
    padding: 6px 12px;
    height: 38px;
    border-radius: 4px !important;
    background-color: #0973BA;
}

.featureDisabled-btn>button:hover {
    background-color: #0973BA;
}

#ssuite-layout-wrapper .side-menu-container .sidebar {
    padding-top: 23px !important;
}

.uploadOfficeLocationDropdown .Select-menu-outer .Select-menu div {
    line-height: 24px;
    white-space: nowrap;
}

.uploadOfficeLocationDropdown .Select-control .Select-value {
    padding-top: 2px;
}

.location-mismatch-modal .modal-dialog {
    display: flex;
    box-shadow: 5px 5px 61px 0px;
    width: 550px;
    height: 266px;
    border-radius: 4.8000001907px;
    border: 1px;
}

.location-mismatch-modal .modal-dialog .modal-content {
    border-radius: 4.8px;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
}

.location-mismatch-modal .modal-dialog .modal-content .modal-header {
    background-color: #fff;
    color: #212529;
    border-bottom: 1px solid #dee2e6;
    line-height: 24px;
    font-size: 20px;
    padding: 11px 1rem;
    border-top-left-radius: 4.8px;
    border-top-right-radius: 4.8px;
    display: flex;
}

.location-mismatch-modal .modal-dialog .modal-content .modal-header .closeIcon {
    color: rgba(0, 0, 0, 0.5);
    width: 21px;
    line-height: 24px;
    cursor: pointer;
}

.location-mismatch-modal .modal-dialog .modal-content .modal-header .modal-title {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #212529;
}

.location-mismatch-modal .modal-dialog .modal-content .modal-body {
    height: fit-content;
    background-color: #fff;
    font-size: 14px;
    padding-bottom: 0px !important;
}

.location-mismatch-modal .modal-dialog .modal-content .modal-body .warning-alter {
    line-height: 18px;
    padding: 0.5rem 0;
}

.location-mismatch-modal .modal-dialog .modal-content .modal-body .warning-alter p {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #564016;
    width: 490px;
}

.location-mismatch-modal .modal-dialog .modal-content .modal-footer {
    background: #fff;
}

.location-mismatch-modal .modal-dialog .modal-content .modal-footer button {
    width: 100px;
    box-shadow: none;
}

.location-mismatch-modal .modal-dialog .modal-content .modal-footer .btn-secondary {
    height: 38px;
    padding: 6px 12px 6px 12px !important;
    border-radius: 2px !important;
    gap: 8px;
    border: 1px solid #898d91 !important;
    background: #f0f1f1;
    color: #212529;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
}

.location-mismatch-modal .modal-dialog .modal-content .modal-footer .btn-primary {
    height: 38px;
    padding: 6px 12px 6px 12px !important;
    border-radius: 2px !important;
    gap: 8px;
    border: 1px solid #0973ba !important;
    background: #0973ba;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
}

.show-location-validation-field .Select-control {
    border: 1px solid #CC4A43 !important;
}

.step-4 .reviewOfficeLocationDropdown .show-validation-text {
    position: unset !important;
}

.bulk-downloads-options label {
    font-size: 16px;
    font-weight: 500 !important;
    line-height: 19.7px;
}

.download-options-modal .popover {
    background-color: white;
}

#mydownloads-popover>div.arrow {
    left: -5px !important;
}

.bulk-downloads-options input.magic-radio+label:before {
    margin-top: 3px;
    width: 16px;
    height: 16px;
}

.bulk-downloads-options input.magic-radio+label.checked:before {
    background: #0973BA;
    margin-top: 3px;
}

.bulk-downloads-options input.magic-radio+label.checked:after {
    background: #FFFFFF;
    margin-top: 3px;
    left: 4px;
    top: 4px;
}

.authentication-type .country>div.bg {
    position: relative;
}

.header-icon {
    margin-top: 9%;
    margin-right: 15px;
    align-self: center;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.default-access-options .custom-curve-check-box .magic-checkbox+label:before {
    top: 4px;
    width: 17px;
    height: 17px;
}

.default-access-options .magic-checkbox+label:before {
    border: 1.9px solid #D9D9D9;
}

.allow-modify-disable .allow-modify label {
    color: #C4C6C8 !important;
}

.allow-modify-disable .allow-modify label.checked:before {
    background: #c9e2f9 !important;
}

.choosable-signature-control,
.textbox-signature-control {
    position: absolute;
    z-index: 5;
    cursor: auto !important;
    background: transparent;
    overflow: hidden;
}

.choosable-signature-control .remove-handle {
    top: 0px;
    background: #000;
    line-height: 0.8 !important;
    border-radius: 51%;
    width: 18px;
    height: 18px;
    color: #fff;
    font-size: 8px;
    cursor: pointer;
    right: 0px;
}

.choosable-signature-control .tooltip-edit {
    cursor: pointer;
    position: absolute;
    left: 5px;
    bottom: 0px;
    margin: 0px;
    float: left;
    line-height: 1;
    background-color: white;
    border-radius: 50%;
    font-size: 16px;
}

.choosable-signature-control .add-control-btn {
    cursor: pointer;
    position: absolute;
    left: 10px;
    bottom: 2px;
}

.conditional-control-options {
    position: absolute;
    height: 135px;
    width: 30px;
    background: black;
    padding: 0px 8px;
    border-radius: 2px;
}

.conditional-control-options>div {
    padding: 7px 0px;
}

.conditional-control-options div {
    position: relative;
    display: inline-block;
}

.conditional-control-options div[aria-disabled="true"] {
    cursor: not-allowed;
}

.conditional-control-options.disabled div[data-title]:hover::after {
    content: none;
}

.conditional-control-options div[data-title]:hover::after {
    content: attr(data-title);
    position: absolute;
    bottom: -20px;
    left: 18px;
    background: #D9D9D9 !important;
    font-size: 14px !important;
    border-radius: 2px !important;
    padding: 2px 10px;
    white-space: nowrap !important;
}

.draggable-btn-control,
.choosable-control-item {
    cursor: move !important;
    font-size: 14px;
}

.choosable-control-item {
    white-space: nowrap;
}

.draggable-btn-control {
    margin-left: 15px;
    position: absolute;
    top: 40%;
}

.choosable-control-group {
    position: absolute;
    z-index: 1;
    background: linear-gradient(90deg, #542D87 5px, rgba(251, 245, 235, 0.5) 4px);
    border-radius: 4px;
}

.choosable-textbox-signature-control {
    background: none !important;
}

.radio-control-input {
    border: 1px solid #898d91;
    color: #212529;
    border-radius: 3px;
    font-size: 14px;
    line-height: 26px;
    padding: 0.1rem 0.2rem 0.1rem 0.5rem;
    width: 52px;
    position: relative;
    display: inline-block;
    user-select: none;
}

.radio-control-input input {
    border: 0px;
    width: 22px;
    display: inline-block;
}

.radio-control-input .arrow {
    position: absolute;
    height: 50%;
    display: inline;
    padding-left: 2px;
    cursor: pointer;
}

.radio-control-input .arrow-up {
    top: 0px;
}

.radio-control-input .arrow-down {
    bottom: 0px;
}

.change-office-location-modal .Select-control .Select-multi-value-wrapper {
    width: 100%;
}

.change-office-location-modal .modal-dialog {
    width: 700px;
}



.conditional-control-settings-options {
    margin: 10px 5px;
}

.conditional-control-settings-options span {
    margin-left: 8px;
    padding-bottom: 16px;
    vertical-align: text-bottom;
    cursor: pointer;
}

.conditional-control-settings-options input {
    height: 31px;
    width: 85%;
    background-color: #F0F1F1;
    color: #C4C6C8;
    font-size: 16px;
    border-radius: 4px;
    padding-left: 9px;
    border: 1px solid #898D91;
    font-weight: 400;
}

.conditional-control-settings-panel button {
    color: #0973BA;
    border: 1px solid #0973BA;
    height: 31px;
    text-align: center;
    width: 95%;
    font-size: 14px;
    background-color: #FFFFFF;
    border-radius: 2px;
}

.conditional-control-settings-panel button:disabled {
    color: #C4C6C8;
    border: 1px solid #C4C6C8;
    cursor: not-allowed;
    background: #F0F1F1;
}

.conditional-control-settings-panel button span {
    margin: 0 5px 0px 0;
    vertical-align: text-bottom;
}

.conditional-control-settings-panel-header {
    background-color: #F0F1F1 !important;
    height: 33px;
    align-items: center;
    padding: 0px 10px;
}

.conditional-control-settings-panel-header div {
    flex-grow: 1;
    margin-left: 10px;
}

.conditional-control-panel .card-body {
    padding: 0px !important;
}

.conditional-control-rules-card {
    border: 1px solid #C4C6C8;
    height: auto;
    border-radius: 4px;
    background: #F0F1F14D;
    padding: 10px;
    margin-bottom: 15px;
}

.conditional-control-rules-card .control-option-dropdown .Select--single {
    display: inline-block;
    width: 50%;
}

.conditional-control-rules-card .control-option-dropdown .Select-control {
    height: 31px;
    border-radius: 4px !important;
}

.conditional-control-rules-card .control-option-dropdown .Select-input {
    height: 31px;
}

.conditional-control-rules-card hr {
    border: 1px solid #C4C6C8;
    margin: 8px 0px 20px 0px !important;
    width: 95%;
}

.conditional-control-rules-card .selected-option-control {
    font-size: 14px;
    font-weight: 400;
    color: #6B7075;
    margin: 10px 6px 0px 5px;
    display: flex;
}

.conditional-control-rules-card .error-text {
    color: #CC4A43;
    font-size: 12px;
    font-weight: 400;
    margin: 4px 0px;
}

.conditional-control-rules-panel-header .when-box {
    border: 1px solid #C4C6C8;
    background: #F0F1F14D;
    height: 70px;
    border-radius: 4px;
    font-size: 14px !important;
    font-weight: 400;
    margin: 12px 0 12px 0;
    padding: 9px;
}

.conditional-control-rules-panel-header {
    margin-bottom: 15px;
}

.conditional-control-rules-panel-header .when-control-box {
    color: #542D87;
    background: #EEEAF3;
    border: 1px solid #542D87;
    height: 23px;
    width: 105px;
    margin-right: 10px;
    margin-top: 5px;
    padding-left: 5px;
    border-radius: 4px;
    display: inline-block;
    width: fit-content;
    padding-right: 5px;
}

.conditional-control-rules-panel {
    padding: 12px 16px;
}

.conditional-control-rules-panel .add-new-rule a,
.conditional-control-rules-panel .add-new-rule a:hover {
    color: #0973BA;
    text-decoration: underline;
    border: none;
    background: none;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.conditional-control-rules-panel .add-new-rule a span {
    margin: 0 5px 0px 0;
    vertical-align: text-bottom;
}

.conditional-control-rules-card .disabled {
    color: #C4C6C8 !important;
    cursor: default !important;
}

.conditional-control-rules-panel .add-new-rule a.disabled,
.conditional-control-rules-card .control-select-field-button[disabled] {
    color: #C4C6C8 !important;
    cursor: not-allowed !important;
}

.conditional-control-rules-card .control-select-field-button[disabled] {
    border: 1px solid #C4C6C8 !important;
}

.conditional-control-rules-card .control-select-field-button {
    width: 100%;
    height: 31px;
    border-radius: 2px;
    border: 1px solid #0973BA;
    color: #0973BA;
    background: #F0F1F14D;
}

.conditional-control-rules-card .control-select-field-button[title="Done"] {
    background: #0973BA;
    color: #F8F9FA;
}

.conditional-control-panel-buttons {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 120;
    width: 22%;
    max-height: 45px !important;
    min-height: 45px !important;
    background: #FFFFFF;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20px;
    border-bottom: 1px solid #e8e8e8;
}

.conditional-control-panel-buttons button {
    height: 31px;
    padding: 0px 12px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    width: 93px;
}

.conditional-control-panel-buttons .discard-button {
    border: 1px solid #898D91;
    background: #F0F1F1;
    color: #212529;
    margin-right: 10px;
}

.conditional-control-panel-buttons .save-button {
    border: 1px solid #0973BA;
    background: #0973BA;
    color: #FFFFFF;
    margin-right: 15px;
}

.custom-sfPanel-body #awesome-pdf-viewer.view-layout>div.header {
    position: relative !important;
}

.selcting-field-button {
    position: absolute;
    left: 40%;
    bottom: 40%;
    background: #ffffff;
    border-radius: 70%;
    cursor: pointer;
}



.conditional-control-settings-panel .magic-checkbox+label {
    padding-left: 22px;
    padding-top: 3px;
}

.conditional-control-settings-panel .custom-curve-check-box {
    padding-left: 0px !important;
}

.conditional-control-settings-panel .custom-curve-check-box label {
    font-size: 16px !important;
    padding-top: 0px !important;
}

.selcting-field-button-radio {
    position: absolute;
    left: 45%;
    top: -10px;
    font-size: 9px;
    background: #ffffff;
    border-radius: 70%;
    cursor: pointer;
    z-index: 5;
}

.selcting-field-button-checkbox {
    position: absolute;
    left: 30%;
    top: -12px;
    background: #ffffff;
    border-radius: 70%;
    cursor: pointer;
}

.selcting-field-button-text {
    position: absolute;
    left: 48%;
    top: -8px;
    font-size: 9px;
    background: #ffffff;
    border-radius: 70%;
    cursor: pointer;
    z-index: 5;
}

.conditional-control-settings-panel .checkbox-label {
    margin-top: 10px;
    width: 98%;
    height: 31px;
    border-radius: 4px;
    border: 1px solid var(--Gray-400, #898D91);
    background: var(--Gray-White, #FFF);
}

.checkbox-control-action {
    display: inline;
    position: absolute;
    bottom: 3px;
    right: 4px;
    cursor: context-menu;
}

.signature-control-panel .Select-menu-outer .recipient-option {
    padding: 5px 15px !important;
}

.signature-control-panel .Select-menu-outer .Select-option {
    padding: 0px !important;
}

.signature-control-panel .Select-menu-outer {
    border-top-width: 0px !important;
}

.signature-control-panel .Select-control .Select-value,
.signature-control-panel .Select-control .Select-input {
    padding-left: 15px !important;
}

.signature-control-panel .select {
    color: #212529 !important;
}

.signature-control-panel .Select-menu-outer .Select-menu {
    max-height: 180px !important;
}

.signature-control-panel .Select.is-open .Select-control {
    z-index: 2;
}

.signature-control-panel .Select .Select-control {
    border: 1px solid #898D91;
}

.signature-control-panel .Select-menu-outer .Select-option,
.signature-control-panel .Select-value .Select-value-label {
    color: #212529 !important;
}


.signature-control-panel .Select-control .Select-input,
.signature-control-panel .Select-control .Select-input input {
    width: 100% !important;
    max-width: 100%;
}

.signature-control-panel .Select-control .Select-value+.Select-input,
.signature-control-panel .Select-control .Select-value+.Select-input input {
    width: auto !important;
    max-width: auto;
}

.resizeable_action {
    position: absolute;
    right: -2px;
    bottom: -3px;
    z-index: 10;
    cursor: se-resize;
}

.delete-conditional-control-popup .modal-dialog {
    max-width: 500px !important;
}

.nameColumnClass {
    overflow: visible !important;
}

.nameColumnClass .nameCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nameColumnClass .nameCell .linkIconContainer {
    display: flex;
    align-items: center;
    margin-top: -5px !important;
}

.nameColumnClass .nameCell .linkIconContainer button {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
    background-image: none !important;
    outline: none !important;
}

.nameColumnClass .nameCell .linkIconContainer button:active {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
    background-image: none !important;
    outline: none !important;
}

.nameColumnClass .nameCell .linkIconContainer button:focus {
    padding: 0px !important;
    box-shadow: none !important;
    background: none !important;
    background-image: none !important;
    outline: none !important;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-toggle::after {
    display: none;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu {
    translate: -50%;
    cursor: default;
}

.dropdown-menu.show[x-placement="top-start"] {
    margin-bottom: 5px !important;
}

.dropdown-menu.show[x-placement="top-start"]:after {
    content: url("src/images/arrow-down.svg");
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    bottom: 9px;
}

.dropdown-menu.show[x-placement="bottom-start"] {
    margin-top: 4px !important;
}

.dropdown-menu.show[x-placement="bottom-start"]:before {
    content: url("src/images/arrow-up.svg");
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    top: -18px;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer {
    padding: 0 1rem;
    width: 320px;
    max-width: 350px;
    min-height: 100px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: normal;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .connectedStyle {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-decoration: underline;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData {
    padding-top: 0rem;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkClientName {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    margin-top: 1rem;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkClientId {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #565a5e;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkEmailId {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #212529;
}

.nameColumnClass .nameCell .linkIconContainer .dropdown-menu .linkedDetailsContainer .linkedData .linkPrimaryUTE .emailId {
    color: #0973ba;
}

.clientAuthenticaionContainer .recipients-body .recipient-name input {
    height: 32px;
    padding: 8px;
    font-size: 14px;
    border-radius: 3.2px !important;
    border: 1px solid #898D91;
    padding-top: 10px;
}

.textbox-signature-control .draggable-btn-control {
    position: static !important;
    margin-left: 19px !important;
}

.textbox-signature-control .resizeable_action {
    right: -6px;
    bottom: -9px;
}

.textbox-signature-control {
    display: flex !important;
    align-items: center;
}

.choosable-textbox-signature-control .remove-handle {
    z-index: 5;
}

.textbox-signature-control .textfield-control {
    margin-left: 7px;
}

.clientAuthenticaionContainer .recipient-email .email-value-render input{
    width: 100% !important;
    font-size: 14px !important;
    height: 27px !important;
    border: none !important;
    color: #333 !important;
    text-overflow: ellipsis !important;
    background: inherit !important;
}

.clientAuthenticaionContainer .recipient-email .Select.has-value .Select-input input{
    display: none !important;
}