h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #373e4a;
}

.header-section-container {
    float: left;
    width: 100%;
    height: 10%;
}

.text-success {
    color: #28a745 !important;
}

.text-danger {
    color: #dc3545 !important;
}

.text-violet {
    color: #940094 !important;
}

.text-grey {
    color: #666666 !important;
}

.text-warning {
    color: orange !important;
}

.text-blue {
    color: #17a2b8;
}

.text-ERO {
    color: #f0ad4e;
}

.MainContent.classic {
    padding-left: 0 !important;
    padding-top: 0;
}

.MainContent.classic.no-sidemenubar {
    padding-left: 28px !important;
}

.clearfix {
    clear: both;
}

.main-body-container {
    padding-top: 5px;
}

.profile-icon {
    margin-left: 5px;
}

.main-body-container.classic {
    -webkit-transition: all 0.5s ease;
    /* Safari */
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.btn {
    font-size: 12px;
    box-shadow: none !important;
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed;
    box-shadow: none;
}

button[data-resource-id].disable,
li[data-resource-id].disable {
    pointer-events: auto !important;
    cursor: not-allowed !important;
    box-shadow: none;
    opacity: 0.65;
}

button[data-resource-id].primary-action-button.disable {
    opacity: 0.50;
}

li[data-resource-id].disable a {
    cursor: not-allowed !important;
}

span[data-resource-id].disable a {
    cursor: not-allowed !important;
    text-decoration: none;
}

li[data-resource-id].disable button {
    cursor: not-allowed !important;
}

div[data-resource-id].disable {
    pointer-events: none !important;
    cursor: not-allowed !important;
    opacity: 0.30;
}

.padTB15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.toolbar .btn {
    padding: 4px 10px 5px 40px;
    position: relative;
}

.toolbar .btn i {
    position: absolute;
    left: 0;
    padding: 8px;
    top: 0;
    border-radius: 4px 0 0 4px;
    font-size: 10px;
}

.toolbar-left-buttons .btn-default {
    margin-right: 4px;
}

.toolbar-left-buttons .btn-default i {
    background: #e6e6e6;
}

.toolbar-right-buttons .btn-info i {
    background: #31b0d5;
}

.modal.upload-doc-modal .modal-dialog {
    min-width: 100%;
}

.btn-group-xs>.btn,
.btn-xs {
    padding: 1px 2px !important;
}

.set-access-modal .modal-dialog {
    max-width: 787px;
}

.sender-delegation-modal .modal-dialog {
    max-width: 787px;
}

.set-access-modal .nav-button {
    margin-top: 87px;
    text-align: center;
    font-size: 26px;
}

.set-access-modal .nav-button a {
    color: #373e4a;
}

.set-access-modal .nav-button a:hover,
.set-access-modal .nav-button a:focus {
    color: #818da2;
}

.modal.upload-doc-modal .modal-dialog .divDocumentUpload {
    border: 4px dashed #bababa;
    padding: 8px;
}

.modal.upload-doc-modal .modal-dialog .divDocumentUpload>img {
    max-width: 100%;
}

.modal.upload-doc-modal .modal-dialog table thead th {
    background-color: #f5f5f6;
    color: #a6a7aa;
    font-weight: 400;
}

.modal.upload-doc-modal .modal-dialog table tbody td {
    background-color: #f8f8f8;
}

.modal.upload-doc-modal .modal-dialog .table>thead>tr>th,
.modal.upload-doc-modal .modal-dialog .table .table-bordered>tbody>tr>th,
.modal.upload-doc-modal .modal-dialog .table .table-bordered>tfoot>tr>th,
.modal.upload-doc-modal .modal-dialog .table .table-bordered>thead>tr>td,
.modal.upload-doc-modal .modal-dialog .table .table-bordered>tbody>tr>td,
.modal.upload-doc-modal .modal-dialog .table .table-bordered>tfoot>tr>td {
    border: 1px solid #ebebeb;
}

.modal-body .alert {
    margin: -14px -14px 20px -14px;
    border: none;
    border-radius: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.user-assignment-content table td.button-cell,
.company-assignment-content table td.button-cell {
    overflow: visible;
}

.user-assignment-content table td.button-cell .button-group>.btn-toolbar,
.company-assignment-content table td.button-cell .button-group>.btn-toolbar {
    float: left;
}

.user-assignment-content table #process-return-dropdown,
.company-assignment-content table #process-return-dropdown,
#delivered-return-dropdown {
    padding: 2px 10px;
    padding-top: 4px;
    margin-top: -5px;
    height: 30px;
    border-radius: 0px !important;
    font-size: smaller;
    background: transparent;
    color: #303641;
    box-shadow: none;
}

.user-assignment-content table td.button-cell .button-group>.btn>.glyphicon,
.company-assignment-content table td.button-cell .button-group>.btn>.glyphicon {
    font-size: 8px;
    padding: 6px 11px;
}

.user-assignment-content .react-bs-container-body,
.company-assignment-content .react-bs-container-body {
    overflow: inherit;
}

#pageDropDown {
    display: none;
}

.react-bs-table-tool-bar>.row>div:nth-child(1) {
    float: right;
}

.user-assignment-content .react-bs-table .table-bordered>thead>tr>th,
.company-assignment-content .react-bs-table .table-bordered>thead>tr>th {
    background: #f5f5f6;
}

.react-bs-table .table-bordered>tbody>tr>td {
    padding-top: 3px;
    padding-bottom: 1px;
}

.form-horizontal .text-left {
    text-align: left !important;
}

.modal.clientinfo-edit-modal .modal-dialog {
    width: 36%;
}

h4,
.h4 {
    font-size: 15px;
}

.btn-default {
    color: #303641;
    background-color: #f0f0f1;
    border-color: #f0f0f1;
}

.modal.clientinfo-edit-modal .panel {
    border-radius: 0;
}

.form-control {
    border-radius: 3px;
    font-size: 12px;
}

.form-check-label {
    font-weight: normal;
}

.modal .modal-content {
    border-radius: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}

.modal-dialog .modal-content {
    border-radius: 1px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
}

.modal-footer .btn+.btn {
    margin-left: 10px;
}

.modal-footer button,
.card-footer button {
    padding: 6px 9px 6px 9px !important;
    min-width: 100px;
    width: auto;
    font-size: 16px;
    height: 38px;
    border-radius: 2px !important;
}

div.common-modal-footer button.modal-primary-button,
div.common-modal-footer button.modal-secondary-button {
    height: 38px;
    padding: 6px 20px 6px 20px !important;
    border-radius: 2px !important;
    font-size: 16px;
}

.modal-footer-button-cancel {
    border: 1px #898D91 solid !important;
}

.modal-footer-button-save {
    background-color: #0973BA !important;
}

.main-body-container.classic>div>h3 {
    margin-top: -15px;
}

.panel-title i {
    color: #aaa;
    margin-right: 10px;
    text-shadow: #ffffff -2px 2px 1px;
}

.nopadding-left {
    padding-left: 0 !important;
}

.nopadding-right {
    padding-right: 0 !important;
}

.nopadding {
    padding: 0 !important;
}

.icon-textbox .glyphicon {
    position: absolute;
    background: #eee;
    padding: 10px;
}

.icon-textbox input {
    padding-left: 40px;
}

.btn.icon-button {
    padding: 4px 10px 5px 40px;
    position: relative;
    background: #fff;
}

.btn.icon-button i {
    position: absolute;
    left: 0;
    padding: 8px;
    top: 0;
    border-radius: 4px 0 0 4px;
    font-size: 10px;
    background: #e6e6e6;
}

.tab-content>.active.delivery-options-tab>div {
    background: #fff;
}

.delivery-options-tab .content-box h4 {
    color: #1a8fbf;
    font-size: 16px;
}

.radio-inline input[type="radio"] {
    margin-top: 0;
}

.form-group .fa-question-circle {
    font-size: 14px;
    color: #ffdb6f;
}

.group-zoom-modal canvas {
    margin: 0 auto;
}

.draggable-signature {
    border: 1px solid #666;
    position: absolute;
    z-index: 5;
    padding-left: 10px;
    padding-top: 3px;
    border-radius: 4px;
    cursor: move;
}

.draggable-signature .remove-handle,
.choosable-control-group .remove-handle{
    top: -9px;
    background: #000;
    line-height: 0.8 !important;
    border-radius: 51%;
    width: 18px;
    height: 18px;
    color: #fff;
    font-size: 8px;
    cursor: pointer;
    right: -10px;
}

.draggable-signature .glyphicon-pencil {
    padding: 4px;
    background: #f0f0f1;
}

.draggable-signature .glyphicon-calendar {
    padding: 4px;
    background: #f0f0f1;
}

.draggable-signature .glyphicon-font {
    padding: 4px;
    background: #f0f0f1;
}

.esignControlWrapper .boxshadow {
    box-shadow: 0px 0px 10px 5px rgba(242, 227, 12, 1);
}

@media only screen and (min-width: 768px) {
    .right-panel {
        width: 550px;
    }
    .conditional-control-panel-buttons {
        width: 325px;
    }
}

@media only screen and (max-width: 768px) {
    .right-panel {
        width: 260px;
    }
    .conditional-control-panel-buttons {
        width: 260px;
    }
}

@media only screen and (max-width: 768px) {
    .maximized-menu .overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        cursor: pointer;
    }
    .modal.clientinfo-edit-modal .modal-dialog {
        width: 90%;
    }
}

@media only screen and (max-width: 991px) {
    .menu-toggle-icon {
        left: 30.5%;
    }
}

.compulsory-star {
    color: #c50505;
    position: absolute;
    top: 3px;
    font-size: 7px;
    padding-left: 3px;
}

.txt-ar {
    text-align: right;
}

.txt-al {
    text-align: left;
}

.marL10 {
    margin-left: 10px;
}

.marL0 {
    margin-left: 0px;
}

.marL15 {
    margin-left: 15px;
}

.marL80 {
    margin-left: 80px;
}
.marL73{
    margin-left: 73px;
}
.marB10 {
    margin-bottom: 10px;
}

.marB10I {
    margin-bottom: 10px !important;
}

.padT07 {
    padding-top: 7px;
}

.padT07-i {
    padding-top: 7px !important;
}

.padT08-i {
    padding-top: 8px !important;
}

.padT15 {
    padding-top: 15px;
}

.padR25 {
    padding-right: 25px;
}

.padL16 {
    padding-left: 16px !important;
}

.padL28 {
    padding-left: 28px !important;
}

.pad05REM {
    padding: 0.5rem;
}

.marL0-i {
    margin-left: 0px !important;
}

.fontSize14 {
    font-size: 14px;
}

.fontSize15 {
    font-size: 15px !important;
}

.fontSize16 {
    font-size: 16px;
}

.fontWeight400 {
    font-weight: 400;
}

.fontWeight600 {
    font-weight: 600;
}

.fontWeight700 {
    font-weight: 700;
}

.tpLabelFont {
    font-size: 12px;
    font-weight: bold;
}

.acc-ma-head {
    height: 30px;
    padding-bottom: 10px;
    width: 100%;
    float: left;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #373e4a;
    margin-bottom: 10px;
}

.acc-ma-head-bg {
    background: #fff;
    padding: 0px 2px;
}

.acc-ma-head-txt {
    margin-top: -12px;
    border-bottom: 2px #ebebeb solid;
}

.checkbox-replace,
.radio-replace {
    position: relative;
    padding-left: 0;
}

.btn {
    padding-top: 9px;
    padding-right: 12px;
    padding-left: 34px;
    padding-bottom: 9px;
    position: relative;
    box-shadow: #80808040 0px 0px 3px;
    border: none;
    border-radius: 2px !important;
}

.btn i {
    float: left;
    right: auto;
    left: 0;
    position: absolute;
    top: 0;
    height: 100%;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    padding: 9px 6px;
    font-size: 12px;
    line-height: 1.42857143;
    text-shadow: 0px 1px 0px #ebebeb;
    border-radius: 2px !important;
}

.btn-info {
    background-color: #21a9e1;
    margin-right: 2px !important;
    margin-bottom: 2px !important;
}

.btn-info:hover {
    background-color: #1a8fbf;
}

.btn-info i {
    background-color: #1a8fbf;
}

.btn-white {
    background: #FAFAFA;
    border: 1px #e0e0e0 solid;
    color: #303641;
    margin-right: 2px;
    margin-bottom: 2px;
}

.btn-white:hover {
    background: #ebebeb;
}

.btn-white i {
    text-shadow: 0px 1px 0px #ebebeb;
}

.btn-success i {
    background: #449d44;
}

.btn-danger i {
    background: #c9302c;
}

.btn-default i {
    background: #d4d4d4;
    text-shadow: 0px 1px 0px #ebebeb;
}

.btn-sm [disabled] {
    cursor: not-allowed;
    opacity: 0.65;
}

.button-hide {
    display: none;
}


/*Company Settings starts*/

.company-settings .new-main-content h3 {
    color: #1a8fbf;
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 16px;
}

.new-main-content h4 {
    color: #0973BA;
    font-size: 18px;
}

.company-settings .help-circle {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border: 1px #ffdb6f solid;
    padding: 2px 0px 1px;
    margin-left: 5px;
    font-size: 12px;
    color: #ffdb6f;
}

.company-settings .marB15 {
    margin-bottom: 15px;
}

.company-settings .btn-new {
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 48%, rgba(236, 236, 236, 1) 48%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ececec', GradientType=0);
    border: 1px #4c4c4c solid;
    color: #4c4c4c;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    display: block;
    padding: 7px 0px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
    -moz-box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
    box-shadow: inset 0px 0px 0px 1px rgba(255, 255, 255, 1);
}

.counter-textbox input {
    width: 50px;
    height: 29px;
    border-width: 1px 0px 1px 0px;
    border-color: #CCC;
    border-style: solid;
    text-align: center;
    vertical-align: bottom;
}

.counter-textbox .btn-left-radius {
    border-radius: 4px 0px 0px 4px;
}

.counter-textbox .btn-right-radius {
    border-radius: 0px 4px 4px 0px;
}

.edit-ins-list {
    height: 100px;
    margin-bottom: 10px;
    border: 1px #ebebeb solid;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    height: 240px;
}

.edit-ins-list ul {
    margin: 0px;
    padding: 0px;
}

.edit-ins-list ul li {
    margin: 0px;
    padding: 5px;
}

.edit-ins-list ul li:hover {
    background: #f0f0f1;
    color: #000000;
    cursor: pointer;
}

.activeManual {
    background: #E9E9EE;
    color: #51555d;
}


/*Company settings ends*/

.add-column-value .help-circle {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border: 1px #ffdb6f solid;
    padding: 2px 0px 1px;
    margin-left: 5px;
    font-size: 12px;
    color: #ffdb6f;
}

.custom-column .help-circle {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border: 1px #ffdb6f solid;
    padding: 2px 0px 1px;
    margin-left: 5px;
    font-size: 12px;
    color: #ffdb6f;
}

.custom-column .custom-column-list-group {
    cursor: pointer;
    max-height: 200px;
    height: 200px;
}

.custom-column .list-group {
    padding-left: 0;
    margin-bottom: 20px;
}

.custom-column .col-sm-11 {
    width: 91.66666666666666%;
}

.custom-column .list-group,
custom-column-list-group {
    border: solid;
    border-width: 2px;
    -webkit-border-radius: 5px;
}

.group-management-components .list-border {
    margin: 0px;
    padding: 0px;
    border: 1px solid #ebebeb;
    height: 210px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}

.client-instruction .edit-ins-list {
    height: 100px;
    margin-bottom: 10px;
    border: 1px #ebebeb solid;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-border-radius: 5px;
}

.client-instruction .edit-ins-list ul {
    margin: 0px;
    padding: 0px;
}

.client-instruction .edit-ins-list ul ul {
    margin: 0px;
    padding: 5px;
}

.client-instruction .edit-ins-list ul ul:hover {
    background: #f0f0f1;
    color: #000000;
    cursor: pointer;
}

.client-instruction .edit-ins-list .active {
    background: #E9E9EE;
    color: #51555d;
}

.client-instruction .buttonWidth {
    margin-left: 10px;
}

.client-instruction .active {
    background: #E9E9EE;
    color: #51555d;
}


/*Client Instruction end*/


/*Company settings ends*/

.instruction-set .textStyle {
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    background: #0973ba;
    border: 1px #000000 solid;
    height: 35px;
    line-height: 32px;
    display: block;
    -webkit-border-radius: 5px;
}

.instruction-set .descriptionStyle {
    padding-top: 8px;
}


/*Company settings ends*/


/* ===== Begin Checkbox & Radio ===== */

.checkbox-replace,
.radio-replace {
    position: relative;
    padding-left: 0;
    margin: 0;
}

.radio-inline,
.checkbox-inline {
    display: inline-block;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
}

.checkbox-replace input[type="checkbox"],
.radio-replace input[type="checkbox"],
.checkbox-replace input[type="radio"],
.radio-replace input[type="radio"] {
    opacity: 0;
}

.checkbox-replace .cb-wrapper,
.radio-replace .cb-wrapper {
    position: relative;
    display: inline-block;
    border: 1px solid #ebebeb;
    margin-bottom: 0;
    width: 16px;
    height: 15px;
    padding-left: 0px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-replace .cb-wrapper input,
.radio-replace .cb-wrapper input {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0;
}

.checkbox-replace .cb-wrapper.disabled,
.radio-replace .cb-wrapper.disabled {
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=30);
    opacity: 0.3;
    filter: alpha(opacity=30);
}

.checkbox-replace .cb-wrapper .checked,
.radio-replace .cb-wrapper .checked {
    position: absolute;
    background: #ebebeb;
    display: block;
    left: 2px;
    right: 2px;
    bottom: 2px;
    top: 2px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-transition: 250ms all cubic-bezier(0.455, 0.030, 0.515, 0.955);
    -o-transition: 250ms all cubic-bezier(0.455, 0.030, 0.515, 0.955);
    -webkit-transition: 250ms all cubic-bezier(0.455, 0.030, 0.515, 0.955);
    transition: 250ms all cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

.checkbox-replace .cb-wrapper+label,
.radio-replace .cb-wrapper+label {
    position: relative;
    top: -3px;
    margin-left: 6px;
}

.checkbox-replace:hover .cb-wrapper,
.radio-replace:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(0, 0, 0, .03);
    -webkit-box-shadow: 0 0 0 2px rgba(0, 0, 0, .03);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .03);
}

.checkbox-replace.radio-replace .cb-wrapper,
.radio-replace.radio-replace .cb-wrapper {
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.checkbox-replace.radio-replace .cb-wrapper .checked,
.radio-replace.radio-replace .cb-wrapper .checked {
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
}

.checkbox-replace.checked .cb-wrapper .checked,
.radio-replace.checked .cb-wrapper .checked {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    filter: alpha(opacity=100);
}

.checkbox-replace .cb-wrapper,
.radio-replace .cb-wrapper {
    border: 1px solid #d2d2d2;
}

.checkbox-replace .cb-wrapper .checked,
.radio-replace .cb-wrapper .checked {
    background: black;
}

.checkbox-replace:hover .cb-wrapper,
.radio-replace:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(209.5, 209.5, 209.5, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(209.5, 209.5, 209.5, .1);
    box-shadow: 0 0 0 2px rgba(209.5, 209.5, 209.5, .1);
}

.checkbox-replace.color-primary .cb-wrapper,
.radio-replace.color-primary .cb-wrapper {
    border: 1px solid #303641;
}

.checkbox-replace.color-primary .cb-wrapper .checked,
.radio-replace.color-primary .cb-wrapper .checked {
    background: #303641;
}

.checkbox-replace.color-primary:hover .cb-wrapper,
.radio-replace.color-primary:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(48, 54, 65, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(48, 54, 65, .1);
    box-shadow: 0 0 0 2px rgba(48, 54, 65, .1);
}

.checkbox-replace.color-red .cb-wrapper,
.radio-replace.color-red .cb-wrapper {
    border: 1px solid #ee4749;
}

.checkbox-replace.color-red .cb-wrapper .checked,
.radio-replace.color-red .cb-wrapper .checked {
    background: #ee4749;
}

.checkbox-replace.color-red:hover .cb-wrapper,
.radio-replace.color-red:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(238, 71, 73, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(238, 71, 73, .1);
    box-shadow: 0 0 0 2px rgba(238, 71, 73, .1);
}

.checkbox-replace.color-blue .cb-wrapper,
.radio-replace.color-blue .cb-wrapper {
    border: 1px solid #21a9e1;
}

.checkbox-replace.color-blue .cb-wrapper .checked,
.radio-replace.color-blue .cb-wrapper .checked {
    background: #21a9e1;
}

.checkbox-replace.color-blue:hover .cb-wrapper,
.radio-replace.color-blue:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(33, 169, 225, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(33, 169, 225, .1);
    box-shadow: 0 0 0 2px rgba(33, 169, 225, .1);
}

.checkbox-replace.color-green .cb-wrapper,
.radio-replace.color-green .cb-wrapper {
    border: 1px solid #00a651;
}

.checkbox-replace.color-green .cb-wrapper .checked,
.radio-replace.color-green .cb-wrapper .checked {
    background: #00a651;
}

.checkbox-replace.color-green:hover .cb-wrapper,
.radio-replace.color-green:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(0, 166, 81, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(0, 166, 81, .1);
    box-shadow: 0 0 0 2px rgba(0, 166, 81, .1);
}

.checkbox-replace.color-gold .cb-wrapper,
.radio-replace.color-gold .cb-wrapper {
    border: 1px solid #fad839;
}

.checkbox-replace.color-gold .cb-wrapper .checked,
.radio-replace.color-gold .cb-wrapper .checked {
    background: #fad839;
}

.checkbox-replace.color-gold:hover .cb-wrapper,
.radio-replace.color-gold:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(250, 216, 57, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(250, 216, 57, .1);
    box-shadow: 0 0 0 2px rgba(250, 216, 57, .1);
}

.checkbox-replace.color-white .cb-wrapper,
.radio-replace.color-white .cb-wrapper {
    border: 1px solid #ffffff;
}

.checkbox-replace.color-white .cb-wrapper .checked,
.radio-replace.color-white .cb-wrapper .checked {
    background: #ffffff;
}

.checkbox-replace.color-white:hover .cb-wrapper,
.radio-replace.color-white:hover .cb-wrapper {
    -moz-box-shadow: 0 0 0 2px rgba(255, 255, 255, .1);
    -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, .1);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, .1);
}

.radio label,
.radio-inline label,
.checkbox label {
    min-height: 16px;
    padding-left: 0px;
    font-weight: normal;
}

.radio-inline .cb-wrapper {
    min-height: 16px;
}


/* ===== End Checkbox & Radio ===== */


/* ===== Setting Popup ===== */

.help-icon {
    font-size: 18px;
    color: #ffdb6f;
}

.help-icon:hover,
.help-icon:focus {
    color: #ffaa00;
}

.my-def-help {
    position: absolute;
    right: 15px;
    top: 6px;
    height: 20px;
    width: 20px;
    text-align: center;
}

.upload-help {
    vertical-align: sub;
    padding-left: 5px;
}


/* ===== Begin Common Style ===== */

*:focus {
    outline: none !important
}

h3 {
    font-size: 21px;
}

.marL03 {
    margin-left: 3px;
}

.marL05 {
    margin-left: 5px;
}

.marL07 {
    margin-left: 7px;
}

.marL20 {
    margin-left: 20px;
}

.marL30 {
    margin-left: 30px;
}

.marT0 {
    margin-top: 0px;
}

.marT2 {
    margin-top: 2px;
}

.marT5 {
    margin-top: 5px;
}

.marT7-i {
    margin-top: 7px !important;
}

.marT10 {
    margin-top: 10px;
}

.marT15 {
    margin-top: 15px !important;
}

.marT16 {
    margin-top: 16px;
}

.marT20 {
    margin-top: 20px;
}

.marT30 {
    margin-top: 30px;
}

.marT20PCT {
    margin-top: 20%;
}

.marT14 {
    margin-top: 14px;
}

.marR03 {
    margin-right: 3px;
}

.marR05 {
    margin-right: 5px;
}

.marR10 {
    margin-right: 10px;
}

.marR25 {
    margin-right: 25px;
}

.marB05 {
    margin-bottom: 5px;
}

.marB15 {
    margin-bottom: 15px;
}

.marB25 {
    margin-bottom: 25px;
}

.marB30 {
    margin-bottom: 30px;
}

.marB35 {
    margin-bottom: 35px;
}

.marB45 {
    margin-bottom: 45px;
}

.padL00 {
    padding-left: 0px !important;
}

marLR03 {
    margin-right: 3px;
    margin-left: 3px;
}

.padR30 {
    padding-right: 30px;
}

.padT05 {
    padding-top: 5px;
}

.padT03 {
    padding-top: 3px;
}

.padT06 {
    padding-top: 6px;
}

.padT25 {
    padding-top: 25px;
}

.padL05 {
    padding-left: 5px;
}

.padL08 {
    padding-left: 8px;
}

.padL15 {
    padding-left: 15px !important;
}

.padL20 {
    padding-left: 20px !important;
}

.padL10 {
    padding-left: 10px;
}

.padR00 {
    padding-right: 0px !important;
}

.padR02 {
    padding-right: 2px;
}

.padR05 {
    padding-right: 5px;
}

.padR15 {
    padding-right: 15px;
}

.padR20 {
    padding-right: 20px !important;
}

.padR70 {
    padding-right: 70px;
}

.padT10 {
    padding-top: 10px;
}

.padB10 {
    padding-bottom: 10px;
}

.txt-ar {
    text-align: right;
}

.txt-ac {
    text-align: center;
}

.dis-inline {
    display: inline-block !important;
}

.btn-only {
    padding: 6px 12px !important;
}

input:focus {
    outline: none;
}

.float-none {
    float: none;
}

.btnuploadscancopy {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    background-color: transparent;
    color: transparent;
    width: 40%;
    height: 100%;
}

.btnuploadscancopyw100 {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    background-color: transparent;
    color: transparent;
    width: 100%;
    height: 100%;
}

table th {
    border-bottom-width: 0px !important;
}

table td {
    vertical-align: middle !important;
}

table .tbl-btn-danger {
    font-size: 8px;
    border: none;
    padding: 7px 9px 5px;
    margin-left: 3px;
}

.menu-toggle-icon {
    font-size: 11px;
    width: 25px;
}

.width100 {
    width: 100%;
}

.width118 {
    width: 118px;
}

.width122 {
    width: 122px !important;
}

.min-height200 {
    min-height: 200px;
}

.va-supper {
    vertical-align: super;
}

.va-sub {
    vertical-align: sub;
}

.modal-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: block;
}

.modal-header .modal-title {
    color: #212529;
    font-size: 20px;
    font-weight: 700;
}

.h3Font {
    font-size: 14px;
}

.modal-header .close {
    font-size: 28px;
    padding-top: 17px;
    font-family: Tahoma;
    font-weight: 100;
}

.pos-relative {
    position: relative;
}

.compulsory-star1 {
    color: #c50505;
    font-size: 6px;
    position: absolute;
    right: -10px;
    top: 12px;
}

.compulsory-star-only {
    color: #c50505;
    font-size: 6px;
}

.btn-file {
    overflow: hidden;
    position: relative;
    vertical-align: middle;
    padding: 6px 12px !important;
    height: 34px;
    line-height: 24px;
}

.btn-file>input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translate(-300px, 0) scale(4);
    font-size: 23px;
    direction: ltr;
    cursor: pointer;
}

.fileinput {
    margin-bottom: 9px;
    display: inline-block;
}

.form-control[readOnly] {
    cursor: not-allowed;
    background-color: #fefeff;
    box-shadow: none;
}

.readonly-background-color {
    background-color: #eeeeee !important;
}


/* ===== End Common Style ===== */

.settings-checkbox .checkbox-replace {
    display: inline-block;
}


/* ===== Begin Header, Leftside, Content Style ===== */

.wrapper {
    width: 100%;
    display: table;
    height: 100%;
    table-layout: fixed;
}

.page-container {
    width: 100%;
    display: table;
    height: 100%;
    table-layout: fixed;
}

.sidebar-menu {
    position: relative;
    z-index: 0;
    border-right: 1px solid #ebebec;
    display: table-cell !important;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 225px;
    padding-top: 57px;
}

.main-content {
    width: 100%;
    display: table-cell;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 57px 15px;
}

.footer {
    border-top: 1px solid #ebebec;
    background: #FFFFFF;
    position: fixed;
    bottom: 0px;
    left: 225px;
    right: 0px;
    z-index: 15;
}

.footer .copyright {
    padding: 10px 15px;
}

.finish-processing-modal .modal-dialog {
    width: 40%;
}

.finish-processing-modal .sidebar-menu-inner #finishprocessing-main-menu {
    list-style: none;
    border: 1px solid #ebebec;
}

.finish-processing-modal .sidebar-menu-inner #finishprocessing-main-menu li {
    border-color: #ebebec;
    position: relative;
    margin: 0;
    font-size: 12px;
    border-bottom: none;
    color: #51555d;
    background-image: none;
    border-radius: 0;
}

.finish-processing-modal .sidebar-menu-inner #finishprocessing-main-menu li a {
    position: relative;
    display: block;
    padding: 10px 20px;
    color: #51555d;
    z-index: 2;
    -moz-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
    -o-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
    -webkit-transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
    transition: color 250ms ease-in-out, background-color 250ms ease-in-out;
    padding: 10px 7px !important;
    border-bottom: none;
    font-size: 14px;
    text-decoration: none;
}

.finish-processing-modal .sidebar-menu-inner #finishprocessing-main-menu li>a {
    border-bottom: 1px solid #ebebec;
}

.finish-processing-modal .sidebar-menu-inner #finishprocessing-main-menu li>a:hover {
    background-color: #f7f7f7;
    color: #51555d;
}

div.icon-div {
    padding: 7px 10px 5px 20px;
    position: relative;
    background: #FBF5EB;
    color: #333;
    cursor: pointer;
    height: 38px;
    border: 1px solid #d2d2d2;
    border-radius: 4px 0 0 4px;
}

div.icon-div i {
    position: absolute;
    left: 0;
    padding: 8px;
    top: 0;
    font-size: 10px;
    background: #e6e6e6;
}

.elementDragging {
    z-index: 1000;
    width: 42%;
    border: 0px;
    transform: scale(0.85);
    transition: all 100ms;
}

.filters {
    display: inline-block;
    position: absolute;
    right: 0px;
    z-index: 10;
}

.filters .dropdown-menu li button {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    width: 100%;
    font-size: 14px;
    text-align: left;
    text-decoration: none;
    box-shadow: none;
}

.filters .dropdown-menu li button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.filters .dropdown-menu li button i {
    padding: 5px;
    font-size: 14px;
    margin-left: 10px;
}

.filter-icon {
    padding: 6px 6px 6px 28px !important;
}

.btn-filter {
    padding: 6px 8px;
}

.btn-border {
    border: 1px #dddddd solid;
    background: #FFFFFF;
}

.btn-border:hover {
    background: #f5f5f6 !important;
}

.doc-pagination .pagination {
    margin: 0px 0px 5px 0px !important;
}

.overflowText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.btn-border {
    border: 1px #dddddd solid;
    background: #FFFFFF;
}

.btn-border:hover {
    background: #f5f5f6 !important;
}


/* UserManagement end*/


/* MySettings */

.my-settings .userscanneruploadsign {
    padding: 2%;
    border: 1px solid;
    border-radius: 9px;
    min-height: 100px;
    min-width: 300px;
    max-width: 300px;
}

.ero-signature .userscanneruploadsign {
    padding: 2%;
    border: 1px solid;
    border-radius: 9px;
    min-height: 100px;
    min-width: 300px;
    max-width: 300px;
}


/* MySettings end*/

.client-instruction-dropdown-width {
    width: 100%;
}

.btnuploadsettings {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    filter: alpha(opacity=0);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    background-color: transparent;
    color: transparent;
    width: 100%;
    height: 100%;
}


/* MySettings end*/


/* Delivered Returns */

.delivered-return-download-link {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    list-style-type: none;
    display: contents;
}

.preparer-message .display-button {
    display: none;
}


/* filter start*/

.filter-dropdown {
    width: 360px;
}

.filter-name {
    width: 100%;
    padding: 3px;
}


/*Filter ends */


/*client Tracking start*/

.client-tracking-modal .modal-dialog {
    width: 900px;
    margin: 30px auto;
}


/*client Tracking end*/


/*reminder update start*/

.reminder-update-modal .modal-dialog {
    width: 830px;
}


/*reminder update end*/


/*upload form start*/

.upload-forms-modal .modal-content {
    width: 1135px;
}

.upload-forms-modal .modal-dialog {
    width: 1142px;
}

.overpayment-applied-hide {
    display: none
}

.button-group .list-disabled {
    pointer-events: none;
    opacity: 0.6;
}

.list-disabled {
    pointer-events: none;
    opacity: 0.6;
}


/* Finish processing start*/

.finish-processing-menu-name {
    padding-left: 10px;
}


/* Finish processing end*/

#tax-year-dropdown+ul.dropdown-menu {
    min-width: 10px;
}

.company-logo {
    max-width: 145px !important;
    height: 45px;
}

.company-logo-header {
    width: 215px !important;
    float: left;
}


/*Invoice tab*/

.align-button {
    text-align: center;
}


/*Invoice tab*/


/*ClientInfo*/

.element-padding {
    padding: 1px
}


/*ClientInfo*/


/*EFile tab*/

.legend {
    background-color: #ebebeb;
    padding: 15px;
    margin-bottom: 20px;
    color: #000;
    font-size: inherit;
}


/*EFile tab*/

div.filepicker {
    background-image: url("../../images/dragger.png");
    /* image not coming if giving image path directly*/
    max-height: 325px;
    height: 100vh;
    align-items: center;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
}

.upload-drop-zone {
    background-color: #FAFBFC;
}

div.dz-message {
    display: none
}

.content-Loader::after {
    content: '';
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: black;
    border-top-color: transparent;
    border-width: 4px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
    z-index: 100;
}

.content-loader-text {
    text-align: center;
    background-color: rgba(255, 255, 255, 0.0);
    font-weight: bold;
    font-size: 100%;
    margin-top: 25%;
}

.overlay-loader {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.overlay-loader::after {
    content: '';
    display: block;
    left: 48%;
    top: 40%;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-color: #1A8FBF;
    border-top-color: #F5F5F5;
    border-width: 2px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
    position: absolute;
}

.overlay-loader-text {
    text-align: center;
    /*background-color: rgba(255,255,255,0.0);*/
    /*font-weight: bold;*/
    font-size: 110%;
    color: #AAAAAA;
    position: relative;
    top: 50%;
    left: 0;
    margin-top: 4%;
}

#process-return-dialog .modal-dialog {
    width: 100%;
    margin: auto auto;
    padding: 10px;
}

.panel-heading-icon {
    color: #ccc;
    margin-right: 10px;
    text-shadow: #fcfcfc 0px 2px 1px;
}

.modal-heading-icon {
    color: #555;
    margin-right: 10px;
    font-size: 16px;
    vertical-align: text-top;
}

.form-group-tile {
    background: white;
    border-style: solid solid solid solid;
    padding: 16px 15px;
    margin-top: 10px;
    border-width: 2px 2px 2px 32px;
    border-radius: 5px;
}

.form-group-tile i {
    margin-right: 15px;
    margin-left: -39px;
    font-size: 14px;
    color: #333;
    text-shadow: #f7f7f7 0px 1px 2px;
    /*vertical-align: text-top;*/
}

.form-group-header i {
    margin-right: 15px;
    vertical-align: text-top;
    color: #333 !important;
    text-shadow: white 0px 1px 1px !important;
}

.arrow-icon {
    font-size: 16px;
    color: #333;
    text-shadow: #f7f7f7 0px 1px 2px;
    margin-top: 14px;
}

.fillWidth {
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
}

.button-dropdown-width {
    max-width: 175px;
    min-width: 172px;
}

.disable-tinymce-div {
    pointer-events: none;
}

dl {
    margin-top: 15px;
    font: inherit;
}

dl dt {
    float: left;
    width: 100px;
    text-align: left;
    margin-left: 15px;
}

dt dd {
    margin-left: 115px;
}

pre {
    display: block;
    /*font-family: monospace;*/
    white-space: pre;
    margin: 1em 0;
}


/* Report Filter Changes Starts here*/

ul.filter-item {
    margin: 0px 11px 0px 11px;
}

ul.filter-item i {
    margin: 3px;
    cursor: pointer;
}

ul.filter-item table:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

ul.filter-item table.active {
    background-color: #dfdfea;
}


/* Report Filter Changes Ends here*/

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: -95%;
    max-width: 180px;
    margin-top: -6px;
    margin-right: -1px;
    -webkit-border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    border-radius: 6px 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: left;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    border-right-color: #999;
    margin-top: 8px;
    margin-right: 10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #ffffff;
}

.dropdown-submenu.float-left {
    float: none;
}

.dropdown-submenu.float-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 6px 6px 6px;
    -moz-border-radius: 6px 6px 6px 6px;
    border-radius: 6px 6px 6px 6px;
}

.dropdown-menu-right {
    margin-left: 0;
}

.dropdown-menu-ul {
    right: 100% !important;
    max-width: none !important;
    left: unset !important;
}

.dropdown-submenu-right>a:after {
    float: right !important;
}

.defaul-filter-lable {
    padding: 3px 20px;
}

.applied-filter {
    padding-right: 10px;
    font-style: italic;
    white-space: nowrap;
    overflow-x: clip;
    text-overflow: ellipsis;
    max-width: 200px;
}

.applied-filter-container {
    position: absolute;
    right: 0%;
    top: 12px;
}

.ddl-icon {
    margin-right: 10px;
    margin-left: -10px;
    color: #17a2b8;
    width: 15.75px;
}

.ddl-icon:hover {
    color: #bee5eb;
    text-shadow: grey 0px 1px 1px;
}

.padB7 {
    padding-bottom: 7px !important;
}

.padB15 {
    padding-bottom: 15px;
}

.label-blue {
    background-color: #00b0f0 !important;
    color: #fff;
}

.custom-toggle-switch {
    position: relative;
    display: block;
    vertical-align: top;
    width: 75px;
    height: 25px;
    padding: 3px;
    margin: 0;
    background: linear-gradient(to bottom, #eeeeee, #FFFFFF 25px);
    background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF 25px);
    border-radius: 18px;
    box-shadow: inset 0 -1px white, inset 0 1px 1px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    box-sizing: content-box;
}

.custom-toggle-switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-sizing: content-box;
}

.custom-toggle-switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    text-transform: uppercase;
    background: #eceeef;
    border-radius: inherit;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
    box-sizing: content-box;
}

.custom-toggle-switch-label:before,
.custom-toggle-switch-label:after {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    box-sizing: content-box;
}

.custom-toggle-switch-label:before {
    content: attr(data-off);
    right: 11px;
    color: #aaaaaa;
    text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}

.custom-toggle-switch-label:after {
    content: attr(data-on);
    left: 11px;
    color: #FFFFFF;
    text-shadow: 0 1px rgba(0, 0, 0, 0.2);
    opacity: 0;
}

.custom-toggle-switch-input:checked~.custom-toggle-switch-label {
    background: #21a9e1;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}

.custom-toggle-switch-input:checked~.custom-toggle-switch-label:before {
    opacity: 0;
}

.custom-toggle-switch-input:checked~.custom-toggle-switch-label:after {
    opacity: 1;
}

.custom-toggle-switch-handle {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 21px;
    height: 22px;
    background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
    background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
    border-radius: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
}

.custom-toggle-switch-handle:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: linear-gradient(to bottom, #eeeeee, #FFFFFF);
    background-image: -webkit-linear-gradient(top, #eeeeee, #FFFFFF);
    border-radius: 6px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
}

.custom-toggle-switch-input:checked~.custom-toggle-switch-handle {
    left: 56px;
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.custom-toggle-switch-small {
    width: 56px !important;
    height: 18px !important;
}

.custom-toggle-switch-handle-small {
    top: 6px !important;
    left: 8px !important;
    width: 12px !important;
    height: 12px !important;
}

.custom-toggle-switch-input:checked~.custom-toggle-switch-handle-small {
    left: 44px !important;
}


/* Transition
========================== */

.custom-toggle-switch-label,
.custom-toggle-switch-handle {
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
}


/*  
========================== */

.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
}

.react-tagsinput--focused {
    border-color: #a5d24a;
}

.react-tagsinput-tag {
    background-color: #fff2ca;
    border-radius: 2px;
    border: 1px solid #FFC107;
    color: black;
    display: inline-block;
    font-family: sans-serif;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " ×";
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 100%;
}


/* BUG 23653, Fix for: Some part of filter and export to excel button container 
	is overlapped by the react-bs-table-tool-bar div*/


/*.react-bs-table-tool-bar > div.row > div:first-child {
    display: none;
}*/

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: .75rem;
}

.card-title i {
    color: #aaa;
    margin-right: 10px;
    text-shadow: #ffffff -2px 2px 1px;
}

.accordion .card {
    overflow: visible;
}

.h5,
h5 {
    font-size: 1.25rem;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Mulish", "Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    /*font-size: 12px;*/
}

.bg {
    background: linear-gradient(to top right, #060628, #1f245a, #682359);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.text {
    position: fixed;
    top: 50%;
    left: 50%;
    color: white;
    -webkit-transform: translate(350px, -200px);
    transform: translate(350px, -200px);
    z-index: 10;
    padding-left: 20px;
}

.text .title {
    font-size: 20px;
    line-height: 20px;
    display: block;
    margin-bottom: 5px;
}

.text .name {
    display: block;
    opacity: 0.5;
}


/*   Process Return Modal  */

.modal-header .modal-header-button {
    padding: 3px 5px;
    float: right;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    opacity: .4;
    margin-right: 2px;
    border: 1px solid transparent;
    background-color: transparent;
}

.modal-header .modal-header-button:hover:not([disabled]) {
    background-color: #efeaea;
    text-shadow: 0 1px 0 #fff;
    border: 1px solid #c1c1c1;
}

.custom-modal .custom-modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    cursor: pointer;
}

.custom-modal .modal-title:hover {
    cursor: move;
}

.custom-modal .bottom-bar {
    bottom: 0px;
    left: 0px;
    position: fixed;
    right: 0px;
    z-index: 2000;
    width: 10%;
    position: absolute;
    cursor: pointer;
}

.custom-modal .bottom-bar-item {
    background-color: #4CAF50;
    color: white;
    height: 33px;
    border-radius: 3px 3px 0px 0px;
    align-items: stretch;
    margin-right: 10px;
    padding: 9px;
    display: flex;
}

.custom-modal .bottom-bar-item-icon {
    float: right;
    margin-left: 15px;
}

.custom-modal .bottom-bar-item-title {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
}

.custom-modal .bottom-bar-item:hover {
    background-color: #76bd79;
}

.custom-modal .modal-footer {
    min-height: 60px;
    bottom: 0px;
    position: absolute;
    width: inherit;
}

.custom-modal .modal-body {
    height: 88%;
    overflow: auto;
    padding: 10px !important;
}

.custom-modal .modal-header {
    border-bottom: 1px solid #e5e5e5;
}

.custom-modal .bottom-bar-item-title-icon {
    color: #ffeb3b;
    line-height: initial;
}

.custom-list-group-item-singleline {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px dotted #ddd;
    height: 37px;
    white-space: nowrap;
}

.custom-list-group-item-singleline.custom-active,
.custom-list-group-item-singleline.custom-active:hover,
.custom-list-group-item-singleline.custom-active:focus {
    color: #0c5460;
    background-color: #d1ecf1;
}

.modalIcon {
    color: grey;
    margin-right: 10px;
    vertical-align: text-bottom;
    font-size: 20px;
}

.custom-list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px dotted #ddd;
}


/* custom toggle button css*/

.switch {
    position: relative;
    /* Fixed Calendar issue*/
    /*display: inline-block;*/
    width: 44px;
    height: 18px;
    margin: 0 0 10px 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}


/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


/**/


/*Hiding tiny-mce status bar*/

.mce-statusbar .mce-container-body {
    display: none !important;
}

#mceu_10 {
    box-shadow: none;
    color: black;
}


/*End Hiding tiny-mce status bar*/


/*Start custom-check-box*/

.custom-checkbox {
    padding-left: 3px;
}

.custom-checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
}

.custom-checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.custom-checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    margin-left: -20px;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555;
}

.custom-checkbox input[type="checkbox"] {
    opacity: 0;
}

.custom-checkbox input[type="checkbox"]:focus+label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.custom-checkbox input[type="checkbox"]:checked+label::after {
    font-family: 'Font Awesome 5 Free';
    content: "\f00c";
    font-weight: 900;
}

.custom-checkbox input[type="checkbox"]:disabled+label {
    opacity: 0.65;
}

.custom-checkbox input[type="checkbox"]:disabled+label::before {
    background-color: #eeeeee;
    cursor: not-allowed;
}

.custom-checkbox.checkbox-circle label::before {
    border-radius: 50%;
}

.custom-checkbox.checkbox-inline {
    margin-top: 0;
}

.custom-checkbox-primary input[type="checkbox"]:checked+label::before {
    background-color: #428bca;
    border-color: #428bca;
}

.custom-checkbox-primary input[type="checkbox"]:checked+label::after {
    color: #fff;
}

.draggable-ghost-list-item-icon {
    float: left;
    margin-right: 10px;
    margin-top: 3px;
}

.draggable-ghost-list-center {
    padding-top: 50%;
}


/*End custom-check-box*/

.appTitle {
    display: inline;
    vertical-align: middle;
    margin-left: 15px;
    font-size: 26px;
    /*text-shadow: #585858 -1px -1px 1px;
    color: #dedede;*/
}

.wordbreak {
    word-break: break-word;
}


/* Left Panel style starts here */

.bookmarksPane .card button,
.accordion .card button {
    border: none;
}

.bookmarksPane .card .card-title,
.accordion .card .card-header,
.accordion .card .card-header .card-title {
    font-size: 16px;
    text-align: left;
    margin-bottom: 0;
    margin: 0;
}

.bookmarksPane .accordion .card .card-header {
    display: flex;
    justify-content: space-between;
}

.bookmarksPane .accordion .card .card-header .float-right {
    display: inline-block;
    margin-top: -3px;
}

.card-header {
    color: #333;
    background-color: #f5f5f5;
    border: none;
    padding: 0px;
}


/*--------------------*/


/*Right Panel style starts here*/

.left-panel,
.right-panel {
    /*margin-top: 10px;*/
    border-top: 0px;
    margin-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bookmarksPane {
    overflow-y: auto;
    overflow-x: hidden;
}

.bookmarksPane .panel,
.right-panel .panel {
    border-radius: 0px !important;
    margin-bottom: 5px !important;
}

.right-panel .panel-title {
    cursor: pointer;
}

.bookmarksPane .panel-default .panel-heading,
.right-panel .panel-default .panel-heading {
    color: #fff;
    background-color: #c0bfcf;
    border-color: #ddd;
    border-radius: 0px !important;
    background-color: #EEEEEE;
    color: #696969;
}

.right-panel .panel-title i {
    color: #808080;
    margin-right: 10px;
    text-shadow: 1px 1px 1px white;
}


/*Right Panel style ends here*/

ul[aria-labelledby='move-to-dropdown'] {
    z-index: 100000;
}

.user-management-content table td.button-cell .button-group>.btn-toolbar {
    float: left;
}

.user-management-content table td.button-cell {
    overflow: visible;
}

.user-management-content .react-bs-container-body {
    overflow: inherit;
}

.pageTitle:hover {
    text-decoration-color: #0973BA !important;
    text-decoration: underline;
}

.btn-plus {
    padding: 4px 4px;
    font-size: 12px;
    text-shadow: 0px 1px 0px #ebebeb;
}

.padLR-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.padLR-2 {
    padding-left: 2px;
    padding-right: 2px;
}

.marB0 {
    margin-bottom: 0px;
}

.trash-icon {
    color: #1a8fbf;
}

.cursor-pointer {
    cursor: pointer;
}

.textalign-center {
    text-align: center;
}

.textalign-right {
    text-align: right;
}

.marL0 {
    margin-left: 0px;
}

.padTB7 {
    padding-top: 7px;
    padding-bottom: 7px;
}

.marL8 {
    margin-left: 8px;
}


/*uploaded files table cell styles start*/

.uploaded-file-remove-btn {
    padding-top: 14px;
    padding-bottom: 14px;
}

.uploaded-file-remove-btn i {
    padding: 6px 6px;
}

.uploaded-file-progressbar {
    height: 28px;
    margin-bottom: 5px;
    margin-top: 5px;
}


/*uploaded files table cell styles End*/


/*Start Price Tag*/

.tags {
    float: left;
    position: relative;
    width: 70px;
    height: 26px;
    margin-left: 20px;
    padding: 0 12px;
    line-height: 27px;
    background: rgb(20, 112, 137);
    color: white;
    font-size: 15px;
    text-decoration: none;
    text-align: center;
}

.tags:before {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
}

.tags:after {
    content: "";
    position: absolute;
    top: 11px;
    width: 4px;
    height: 4px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background: #fff;
    -moz-box-shadow: -1px -1px 2px #004977;
    -webkit-box-shadow: -1px -1px 2px #004977;
    box-shadow: -1px -1px 2px #004977;
}

.left-tag {
    -moz-border-radius-bottomright: 4px;
    -webkit-border-bottom-right-radius: 4px;
    border-bottom-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
}

.left-tag:before {
    left: -15px;
    border-color: transparent rgb(20, 112, 137) transparent transparent;
    border-width: 15px 15px 15px 0;
}

.left-tag:after {
    left: -2px;
}

.right-tag {
    -moz-border-radius-bottomleft: 4px;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
}

.right-tag:before {
    right: -13px;
    border-color: transparent transparent transparent rgb(20, 112, 137);
    border-width: 13px 0 13px 13px;
}

.right-tag:after {
    right: -2px;
}

.tags[class*="#147089"] {
    background: rgb(20, 112, 137);
}

.left-tag[class*="#147089"]:before {
    border-color: transparent rgb(20, 112, 137) transparent transparent;
}

.right-tag[class*="#147089"]:before {
    border-color: transparent transparent transparent rgb(20, 112, 137);
}

.tags[class*="#E86650"] {
    background: rgb(232, 102, 80);
}

.left-tag[class*="#E86650"]:before {
    border-color: transparent rgb(232, 102, 80) transparent transparent;
}

.right-tag[class*="#E86650"]:before {
    border-color: transparent transparent transparent rgb(232, 102, 80);
}


/*End Price Tag*/

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.border-radius-0 {
    border-radius: 0px !important;
}

.imageBorder {
    border: 1px solid grey;
    border-radius: 16px;
    padding: 12px;
}

.image-upload>input {
    display: none;
}

.imageupload {
    width: 40px;
    cursor: pointer;
    background: #d3d3d3;
    float: left;
    cursor: pointer;
}

.span-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
}


/* Added refund start*/

#popover-added-refund {
    text-align: center;
}

#popover-added-refund .icon-textbox {
    text-align: initial;
}


/* Added refund end*/


/*height File uploaded Success list */

.Modal-UploadUl {
    margin-left: 8px;
    max-height: 150px;
    overflow-y: auto;
}

.passwordHeader {
    width: 100%;
    text-align: left;
    font-weight: bold;
    font-size: 16px;
    color: #1a8fbf;
}

.borderBottom01 {
    border-bottom: 1px solid #d3d3d3;
}

.marL15 {
    margin-left: 15px;
}

.marR15 {
    margin-right: 15px;
}

.marL50 {
    margin-left: 50px;
}

.marL60 {
    margin-left: 60px;
}

.marL70 {
    margin-left: 70px;
}

.marL75 {
    margin-left: 75px;
}

.marL33 {
    margin-left: 33px;
}

.marL95 {
    margin-left: 95px;
}
.marL7half{
    margin-left: 7.5px;
}
.member-of .nav-button {
    margin-top: 87px;
    text-align: center;
    font-size: 26px;
}

.member-of .nav-button a {
    color: #373e4a;
}

.member-of .nav-button a:hover,
.member-of .nav-button a:focus {
    color: #818da2;
    cursor: pointer;
}


/*Custom radio button start*/

.magic-checkbox+label,
.magic-radio+label {
    font-weight: normal !important;
}

@keyframes hover-color {
    from {
        border-color: #c0c0c0;
    }
    to {
        border-color: #3e97eb;
    }
}

.magic-radio,
.magic-radio-sm,
.magic-checkbox,
.magic-checkbox-sm {
    position: absolute;
    display: none;
}

.magic-radio[disabled],
.magic-checkbox[disabled],
.magic-radio-sm[disabled],
.magic-checkbox-sm[disabled] {
    cursor: not-allowed;
}

.magic-radio+label,
.magic-checkbox+label,
.magic-radio-sm+label,
.magic-checkbox-sm+label {
    position: relative;
    display: block;
    padding-left: 30px;
    cursor: pointer;
    vertical-align: middle;
}

.magic-radio-sm+label,
.magic-checkbox-sm+label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
}

.magic-radio+label:hover:before,
.magic-checkbox+label:hover:before,
.magic-radio-sm+label:hover:before,
.magic-checkbox-sm+label:hover:before {
    animation-duration: 0.4s;
    animation-fill-mode: both;
    animation-name: hover-color;
}

.magic-radio+label:before,
.magic-checkbox+label:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    border: 1px solid #9d9d9d;
}

.magic-radio-sm+label:before,
.magic-checkbox-sm+label:before {
    position: absolute;
    top: -5px;
    left: -1px;
    display: inline-block;
    width: 15px;
    height: 15px;
    content: '';
    border: 1px solid #9d9d9d;
}

.checkbox-padding .magic-radio-sm+label:before,
.checkbox-padding .magic-checkbox-sm+label:before {
    top: -9px;
}

.magic-radio+label:after,
.magic-checkbox+label:after,
.magic-radio-sm+label:after,
.magic-checkbox-sm+label:after {
    position: absolute;
    display: none;
    content: '';
}

.magic-radio[disabled]+label,
.magic-checkbox[disabled]+label,
.magic-radio-sm[disabled]+label,
.magic-checkbox-sm[disabled]+label {
    cursor: not-allowed;
    color: #e4e4e4;
}

.magic-radio[disabled]+label:hover,
.magic-radio[disabled]+label:before,
.magic-radio[disabled]+label:after,
.magic-checkbox[disabled]+label:hover,
.magic-checkbox[disabled]+label:before,
.magic-checkbox[disabled]+label:after,
.magic-radio-sm[disabled]+label:hover,
.magic-radio-sm[disabled]+label:before,
.magic-radio-sm[disabled]+label:after,
.magic-checkbox-sm[disabled]+label:hover,
.magic-checkbox-sm[disabled]+label:before,
.magic-checkbox-sm[disabled]+label:after {
    cursor: not-allowed;
}

.magic-radio[disabled]+label:hover:before,
.magic-checkbox[disabled]+label:hover:before,
.magic-radio-sm[disabled]+label:hover:before,
.magic-checkbox-sm[disabled]+label:hover:before {
    border: 1px solid #e4e4e4;
    animation-name: none;
}

.magic-radio[disabled]+label:before,
.magic-checkbox[disabled]+label:before,
.magic-radio-sm[disabled]+label:before,
.magic-checkbox-sm[disabled]+label:before {
    border-color: #e4e4e4;
}

.magic-radio:checked+label:before,
.magic-checkbox:checked+label:before,
.magic-radio-sm:checked+label:before,
.magic-checkbox-sm:checked+label:before {
    animation-name: none;
}

.magic-radio:checked+label:after,
.magic-checkbox:checked+label:after,
.magic-radio-sm:checked+label:after,
.magic-checkbox-sm:checked+label:after {
    display: block;
}

.magic-radio+label:before,
.magic-radio-sm+label:before {
    border-radius: 50%;
}

.magic-radio+label:after,
.magic-radio-sm+label:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #3e97eb;
}

.magic-radio:checked+label:before,
.magic-radio-sm:checked+label:before {
    border: 1px solid #3e97eb;
}

.magic-radio:checked[disabled]+label:before,
.magic-radio-sm:checked[disabled]+label:before {
    border: 1px solid #c9e2f9;
}

.magic-radio:checked[disabled]+label:after,
.magic-radio-sm:checked[disabled]+label:after {
    background: #c9e2f9;
}

.magic-checkbox+label:before,
.magic-checkbox-sm+label:before {
    border-radius: 1px;
}

.magic-checkbox+label:after {
    top: 2px;
    left: 7px;
    box-sizing: border-box;
    width: 6px;
    height: 12px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0;
}

.magic-checkbox-indeterminate+label:after {
    top: 4px !important;
    left: 9px !important;
    width: 0px !important;
    height: 12px !important;
    transform: rotate(90deg) !important;
}

.magic-checkbox-sm+label:after {
    top: -5px;
    left: 3px;
    box-sizing: border-box;
    width: 6px;
    height: 11px;
    transform: rotate(45deg);
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    border-top: 0;
    border-left: 0;
}

.magic-checkbox-sm-indeterminate+label:after {
    top: -3px !important;
    left: 5px !important;
    width: 0px !important;
    height: 10px !important;
    transform: rotate(90deg) !important;
}

.magic-checkbox:checked+label:before,
.magic-checkbox-sm:checked+label:before {
    border: #3e97eb;
    background: #3e97eb;
}

.magic-checkbox:checked[disabled]+label:before,
.magic-checkbox-sm:checked[disabled]+label:before {
    border: #c9e2f9;
    background: #c9e2f9;
}


/*Custom radio button end*/

.pos-absolute {
    position: absolute;
}

.marL-20 {
    margin-left: -20px;
}


/*finish popover start*/

.finishPopover {
    max-width: none;
    /* Max Width of the popover (depending on the container!) */
    font-size: 12px;
}

.finishPopover .arrow {
    top: 100%;
}

.finishPopover .custom-list-group-item {
    padding: 6px 15px;
}

.finishPopover hr {
    margin-top: 10px;
    margin-bottom: 10px;
}

.finishPopover .warning {
    color: #8a6d3b;
}

.finishPopover .info {
    color: #31708f;
}

.finishPopover .rdo-btn-group {
    display: inline-flex;
    margin-bottom: 10px;
    padding-left: 5px
}

.finishPopover .popover-content {
    height: 95%
}

.finishPopover .rdo-btn-group div+div {
    margin-left: 20px;
}


/*finish popover end*/

.tabs-left,
.tabs-right {
    border-bottom: none;
    padding-top: 2px;
}

.tabs-left {
    border-right: 1px solid #ddd;
}

.tabs-left>li,
.tabs-right>li {
    float: none;
    margin-bottom: 2px;
}

.tabs-left>li {
    margin-right: -1px;
}

.tabs-left>li.active>a,
.tabs-left>li.active>a:hover,
.tabs-left>li.active>a:focus {
    border-bottom-color: #ddd;
    border-right-color: transparent;
}

.tabs-left>li>a {
    border-radius: 4px 0 0 4px;
    margin-right: 0;
    display: block;
}

.selected-message-view {
    background-color: #efefef;
    min-height: 100px;
    color: black;
    margin-bottom: 0;
    overflow: scroll;
    height: 120px;
}


/* PDFJS Viewer with text layer*/

.pdfTextLayer {
    position: absolute;
    left: 0;
    top: 0;
    right: 15px;
    bottom: 15px;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1.0;
    user-select: text;
}

.annotationLayer {
    position: absolute;
    left: 0;
    top: 0;
    right: 15px;
    bottom: 15px;
    overflow: hidden;
    opacity: 1;
}

.pdfTextLayer>span {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
}

.pdfTextLayer .highlight {
    margin: -1px;
    padding: 1px;
    background-color: rgb(180, 0, 170);
    border-radius: 4px;
}

.pdfTextLayer .highlight.begin {
    border-radius: 4px 0px 0px 4px;
}

.pdfTextLayer .highlight.end {
    border-radius: 0px 4px 4px 0px;
}

.pdfTextLayer .highlight.middle {
    border-radius: 0px;
}

.pdfTextLayer .highlight.selected {
    background-color: rgb(0, 100, 0);
}

.pdfTextLayer ::-moz-selection {
    background: rgb(0, 0, 255);
}

.pdfTextLayer ::selection {
    background: rgb(0, 0, 255);
}

.pdfTextLayer .endOfContent {
    display: block;
    position: absolute;
    left: 0px;
    top: 100%;
    right: 0px;
    bottom: 0px;
    z-index: -1;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pdfTextLayer .endOfContent.active {
    top: 0px;
}

.btn-default-active {
    color: #333;
    background-color: #d4d4d4;
    border-color: #8c8c8c;
}

.pdfTextLayer .endOfContent.active {
    top: 0px;
}

.group-view-content {
    height: 70vh;
    overflow-y: auto;
}

.group-view-hedear {
    background-color: #f9f7f7;
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid #eae7e7;
    border-radius: 3px;
    display: block;
}

.background-light-yellow {
    background-color: #f9f8ed;
}

.color-change-animation {
    animation: colorchange 2s;
    -webkit-animation: colorchange 2s;
}

.group-toolbar .pages-selected {
    padding-left: 4px;
    border-left: 1px solid #b1a2c1;
    margin-left: 5px;
    color: #3F51B5;
}

@keyframes colorchange {
    0% {
        background: #f9f7f7;
    }
    25% {
        background: #eff992;
    }
    100% {
        background: #f9f7f7;
    }
}

.popover-footer {
    padding: 10px;
    text-align: right;
}

.popover-footer>.btn {
    margin-left: 5%;
}

.zIndex10 {
    z-index: 10;
}

.header-delivery-options {
    color: #1a8fbf;
}

.divUploadZone {
    border: 4px dashed #bababa;
    padding: 8px;
}

.fit-to-screen {
    height: 100% !important;
    width: 100% !important;
    left: 0px !important;
    top: 0px !important;
}

.pg-viewer-wrapper {
    height: 700px !important;
    overflow-y: auto !important;
}


/* Custom Pagination*/

.custom-pagination {
    border-radius: 4px !important;
    margin-bottom: 0px !important;
}

.custom-pagination>li {
    display: inline;
}

.custom-pagination>li>a,
.custom-pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    background: #FFFFFF;
    border: 1px #c9edfe solid;
    color: black;
}

.custom-pagination>li:first-child>a,
.custom-pagination>li:first-child>span {
    margin-left: 0;
}

.custom-pagination>li>a:hover,
.custom-pagination>li>span:hover,
.custom-pagination>li>a:focus,
.custom-pagination>li>span:focus {
    z-index: 2;
    color: #23527c;
    background-color: #eee;
    border-color: #ddd;
}

.custom-pagination>.active>a,
.custom-pagination>.active>span,
.custom-pagination>.active>a:hover,
.custom-pagination>.active>span:hover,
.custom-pagination>.active>a:focus,
.custom-pagination>.active>span:focus {
    z-index: 3;
    cursor: default;
    color: white;
    background-color: #1d9bff;
}

.custom-pagination>.disabled>span,
.custom-pagination>.disabled>span:hover,
.custom-pagination>.disabled>span:focus,
.custom-pagination>.disabled>a,
.custom-pagination>.disabled>a:hover,
.custom-pagination>.disabled>a:focus {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #ddd;
}

.custom-pagination-lg>li>a,
.custom-pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    overflow-y: auto;
}

.custom-pagination-lg>li:last-child>a,
.custom-pagination-lg>li:last-child>span {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.custom-pagination-sm>li>a,
.custom-pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.custom-pagination>.active {
    z-index: 0 !important;
    border-color: #47a7f3 !important;
}

.custom-pagination-container {
    display: inline-flex;
}

.pagination-label-container {
    margin: auto;
    margin-left: 10px !important;
}

.custom-pagination-container .search-field {
    width: 30px;
    height: 25px;
    margin: auto;
    text-align: center;
}


/* End */

.datePicker {
    padding-right: 70px;
}

.filePreviewModalContent {
    max-height: 800px;
    max-width: 800px;
}

.filePreviewModalBody {
    height: 800px;
    max-width: 800px;
}

.filePreviewModalContent {
    overflow-y: initial !important
}

.div-height {
    height: 30px;
}

.div-flex {
    display: flex;
}

.year-dropdown-align {
    justify-content: flex-end;
}

.year-dropdown-width {
    width: 68px;
}

.fontfamily-dropdown-width {
    width: 100px;
}

div#attachmentTable td.react-bs-table-no-data {
    border: 0px;
}

.copyIcon {
    display: none;
}

.copy:hover .copyIcon {
    display: inline-block;
}

.datePicker {
    padding-right: 70px;
}

.eye-icon {
    float: right;
    margin-top: -25px;
    padding-right: 15px;
    position: relative;
}

.overlay-content .page-number {
    color: white !important;
    font-size: 12px;
    font-weight: 700;
    padding: 0px 5px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 0px;
    background: rgba(6, 0, 250, 0.38);
}

.overlay-content .action-buttons {
    bottom: 40%;
    left: 50%;
    position: absolute;
    margin-left: -43px;
}

.action-buttons span {
    border: 1px solid #afafaf;
    display: block;
    height: 35px;
    line-height: 35px;
    text-align: center;
    width: 35px;
    background-color: #afafaf;
    border-radius: 25px;
    color: white;
    opacity: 0.2;
    cursor: pointer;
}

.action-buttons a:hover {
    background-color: #8c8c8c !important;
    opacity: 1 !important;
    transition: all 0.3s ease 0s !important;
}

.overlay-content .action-buttons ul {
    display: flex;
    list-style: outside none none;
}

.overlay-content .action-buttons ul li {
    margin-right: 10px;
}

.overlay-content h2 {
    color: #5a5a5a;
    font-size: 20px;
    font-weight: 700;
    left: 40px;
    position: absolute;
    top: 20px;
    transition: all 0.3s ease 0s;
}

.modal-GroupFilePreview {
    width: 60%;
    margin: auto;
    overflow-y: initial !important;
    max-height: 100%;
}

.modal-GroupFilePreviewBody {
    height: 75vh;
    overflow-y: auto;
}

.btn-download-bri {
    margin-right: 2%;
}

.textMessageview {
    background-color: rgb(241, 241, 241);
    height: 132px;
    overflow-y: auto;
    padding: 5px;
}

.word-Visible {
    overflow-wrap: break-word;
    white-space: initial !important;
}

.progress {
    margin-bottom: 0px !important;
}

.pg-viewer-wrapper video {
    width: 100%;
    height: 100%;
}

.taxcaddy-icon {
    font-size: x-large;
    cursor: pointer;
    text-decoration: none !important;
}

.padT08 {
    padding-top: 8px;
}

.full-height {
    height: 100%;
}

#process-return-groups .dropdown-menu>div>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}


/*=======Dropdown start===========*/

#combodefault .col-lg-9 {
    min-height: 250px;
}

#default {
    margin: 0 auto;
    width: 250px !important;
    padding-top: 15px;
}

#combodefault .property-panel-content,
#combopanel .property-panel-content {
    overflow: hidden;
}


/*=======Dropdown end===========*/

.text-message {
    margin-top: 10px;
    border: 1px solid rgb(221, 221, 221);
    padding: 5px;
    height: 290px;
    padding-top: 0px;
    width: 434px;
}

.uploaded-documents-table-container {
    overflow: auto;
    max-height: 70vh;
}

.uploaded-documents-table-container .table th,
.uploaded-documents-table-container .table td {
    padding: 0.25rem;
}

.text-bold {
    font-weight: bold !important;
}

.zindexzero input[type='text'] {
    z-index: 0 !important;
}

.addingvoucher-loader {
    position: absolute !important;
    height: 100%;
}

.upload {
    background-color: #eee;
    color: black;
    padding: 5px 4px;
}

.overflowHidden {
    overflow: hidden;
}

.overflowVisible {
    overflow: visible !important;
}

.bookmark-item {
    padding: 8px 8px;
}

.bookmark-name {
    display: inline;
    margin-right: 8px;
}

.bookmark-name:hover {
    text-decoration-color: #0973BA !important;
    text-decoration: underline;
}

.bookmark-row {
    display: inline-flex;
    width: 100%;
}

.bookmark-icon {
    margin-right: 8px;
    line-height: unset;
}

#process-return-groups-pane-1 .react-datepicker__input-container {
    position: relative;
    display: initial !important;
}

.height-inherit {
    height: inherit;
}

.full-height {
    height: 100%;
}

#process-return-groups .dropdown-menu>div>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.dropdown-menu-ul {
    right: 100% !important;
    max-width: none !important;
    left: unset !important;
}

.dropdown-submenu-right>a:after {
    float: right !important;
}

.bold-label>div>.magic-checkbox+label,
.boldlable>div>.magic-radio+label {
    font-weight: bold !important;
}

.zero-amount-badge {
    background-color: grey;
    border-radius: 25px;
    width: 26px;
    max-height: 19px;
    color: white !important;
}

.delete-zero-amount-button {
    margin-right: 10px;
    background: orange;
    height: 24px;
    width: 24px;
    border-color: #ffc400;
}

.delete-zero-icon {
    padding-bottom: 6px;
    height: 22px;
    width: 17px;
    vertical-align: middle;
}

.access-code-note {
    text-align: left;
    padding-top: 19px;
    font-size: smaller;
    color: #e31313;
    padding-left: 5px;
}

.replace-invoice {
    font-size: 12px;
    border-radius: 0px !important;
    border: 1px solid #ccc;
    color: #eee,
}

.bookmark-right {
    margin-left: auto;
    white-space: nowrap;
}

.bookmark-right i {
    line-height: unset;
}

.bookmark-right i:hover {
    background-color: #d0d0d3;
    border: solid 1px #595959;
    border-radius: 3px 3px;
}

.client-info-payments {}

.bookmark-right .client-info-payments {
    font-family: "'Lucida Console', 'Monaco', 'monospace'";
    margin-right: 8px;
    text-align: right;
    color: red;
}

.client-info-refunds {}

.bookmark-right .client-info-refunds {
    font-family: "'Lucida Console', 'Monaco', 'monospace'";
    margin-right: 8px;
    text-align: right;
    color: green;
}

.textarea {
    overflow: auto;
    resize: vertical;
}

.text-green {
    color: #5CB85C
}

.text-yellow {
    color: #FFD700
}

#div-delivered-returns-search,
#div-archive-returns-search,
#div-inuse-returns-search,
#div-Assignment-Table-search {
    float: left;
}

.react-bs-table-search-form {
    width: 100%;
}

.modal.view-access-code-modal .modal-dialog {
    width: 854px;
}

.overflowTextClientTracking>div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

#ero {
    font-size: 14px !important;
}

#report-problem-text {
    resize: none;
}

.display-checkBox {
    display: inline-block !important;
}

.zeroborderRadius>span {
    border-radius: 0px !important;
}

.fa-stack {
    font-size: 0.45em;
}

.activeSelected {
    background: #3399ff !important;
    color: white !important;
}

.group_user ul li {
    list-style-type: none;
}

.group_user ul li:hover {
    cursor: default !important;
    background: #C5C5C5;
}

input.e-input,
.e-input-group input.e-input,
.e-input-group input,
.e-input-group.e-control-wrapper input.e-input,
.e-input-group.e-control-wrapper input,
.e-float-input input,
.e-float-input.e-input-group input,
.e-float-input.e-control-wrapper input,
.e-float-input.e-control-wrapper.e-input-group input,
.e-input-group,
.e-input-group.e-control-wrapper,
.e-float-input,
.e-float-input.e-control-wrapper {
    border-radius: 0px !important
}

.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 0px !important;
}

.padding-left-6 {
    padding-left: 6%;
}

th[data-field="clientApp"] {
    width: 30%;
}

.modal.recycle-signed-Details-modal .modal-dialog {
    max-width: 686px !important;
}

.modal.signed-Details-modal .modal-dialog {
    max-width: 800px !important;
}

.modal.Delivered-esign-details-modal .modal-dialog,
.modal.Archive-esign-details-modal .modal-dialog {
    max-width: 870px !important;
}

.modal.recall-return-modal .modal-dialog {
    width: 40%;
}

.recall-return-table {
    border-collapse: collapse;
    width: -webkit-fill-available;
}

table.recall-return-table th,
table.recall-return-table td {
    border: 1px solid #ccc;
    text-align: left;
    padding: 5px;
    padding-left: 8px;
}

.custom-multiselect-status {
    margin-left: 8px;
    vertical-align: baseline;
    position: relative;
    bottom: 3px;
}

.custom-singleselect-status {
    vertical-align: baseline;
    position: relative;
    bottom: 3px;
}

.ssr-default {
    pointer-events: none;
    background-color: rgb(241, 236, 236);
}

.minimum-length {
    padding-top: 20px;
    padding-bottom: 20px;
}

.select-age {
    padding-top: 20px;
    padding-bottom: 10px;
}

.age-text {
    float: left;
    font-size: 12px;
    margin-left: 15px;
}

.select-age-dropdown {
    width: 8%;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
}

.password-req-lable {
    color: red;
    /*text-decoration: underline;*/
}

.line-space {
    padding-top: 10px;
    padding-left: 30px;
}

.list-special-char {
    color: green;
    padding-left: 45px;
}

.tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -50px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.Select .Select-menu-outer {
    width: 100% !important;
    overflow: visible !important;
    border-top-right-radius: 4px;
}

.signatureflow-report .react-bs-table .Select .Select-menu-outer {
    width: fit-content !important;
    overflow: visible !important;
    border-top-right-radius: 4px;
    max-height: fit-content !important;
}

.signatureflow-report .react-bs-table .Select .Select-menu-outer .Select-menu {
    max-height: fit-content !important;
}

.select-control {
    width: 140px;
    float: left;
    padding-right: 15px;
}

.settings-subheading {
    font-size: 16px;
    text-decoration-line: underline;
    margin-left: 20px;
}

.marT09 {
    margin-top: 9px;
}

.select-reminder-control {
    width: 100px !important;
}

.bannerHeader {
    color: #FF0020;
    font-size: 1.5rem !important;
    font-weight: bold;
    padding-left: 50px;
    flex: 6;
}

.marTNeg03 {
    margin-top: -3px !important;
}

.Select.is-disabled>.Select-control {
    background-color: #eeeeee !important;
}

.columnvalue {
    overflow: hidden;
    width: 80%;
    display: inline-block;
}

.icon-color {
    color: #15aabf;
    padding-right: 4px;
}

ul.moveable li {
    list-style-image: none;
    border-radius: 4px;
    padding: 4px;
    color: #666;
    cursor: move;
}

ul.moveable li:hover {
    background-color: #eee;
}

.draggable-input:hover {
    cursor: move;
}

span.action-icons>i:hover,
span.action-icons>i {
    cursor: pointer !important;
    pointer-events: auto;
}

div.optionlist {
    position: absolute;
    z-index: 200;
    background-color: #fff;
    left: auto;
    text-align: left;
    list-style: none;
    border: 1px solid #aaa;
    right: 21px;
    white-space: nowrap;
}

div.optionlist>div.item {
    line-height: 24px;
    padding: 0 10px;
}

div.optionlist>div.item-highlighted {
    color: #fff;
    background-color: #0078d7;
}

.isDisabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.isDisabled a {
    color: currentColor;
    display: inline-block;
    /* For IE11/ MS Edge bug */
    pointer-events: none;
    text-decoration: none;
}

.resize-vertical {
    resize: vertical;
}

.resize-horizontal {
    resize: horizontal;
}

.recycle-checkbox {
    margin-top: 4px;
}

.div-chip-list {
    align-items: center;
    border: 2px solid #d4d5d6;
    padding: 4px;
    overflow-y: auto;
    max-height: 100px;
}

.chip-input {
    height: 34px;
    padding: 0 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: none;
    color: #565656;
    -webkit-appearance: none;
}

.chip {
    border: 1px solid #d4d5d6;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 3px 0;
    background: #f6ee934f;
}

.chip>button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chip>.drag {
    cursor: move;
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
}

.suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
    position: absolute;
    z-index: 1;
    background-color: white;
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    background-color: aliceblue;
    cursor: pointer;
}

#sf-txt-area-msg {
    height: 300px;
    margin-top: 2%;
    padding-left: 5px;
}

.sfSigners {
    color: #0973BA !important;
}

.sfSigners:hover {
    text-decoration: underline !important;
}

.sigflow-doc-access-list {
    text-align: center;
    padding-top: 5%;
}

.sfSigners:hover {
    text-decoration: underline !important;
}


/* The switch - the box around the slider */

.toggleSwitch {
    position: relative;
    display: inline-block;
    width: 60px;
}


/* Hide default HTML checkbox */

.toggleSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.signerDelegation-toggleSwitch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 16px;
    margin: 0;
}


/* Hide default HTML checkbox */

.signerDelegation-toggleSwitch input {
    opacity: 0;
    width: 0;
    height: 0;
}


/* The slider */

.columnFilterSlider {
    position: absolute;
    cursor: pointer;
    top: 0;
    width: 40px;
    height: 12px;
    display: block;
    margin: 10px 0px 0px 0px;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3) !important;
    -webkit-transition: 0.5s ease background-color;
    background-color: #d9e3f4;
    border-radius: 20px;
    transition: 0.5s ease background-color
}

.columnFilterSlider:before {
    position: absolute;
    content: "";
    width: 17px;
    height: 17px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 2px 6px 25px #d7d7d7;
    transform: translate(-2px, -2px);
    transition: 0.6s ease transform, 0.6s box-shadow;
}

input[type="checkbox"]:checked+.columnFilterSlider {
    background-color: #92D9F8;
}

input:focus+.columnFilterSlider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.columnFilterSlider:before {
    box-shadow: 7px 6px 25px #115980 inset;
    transform: translate(25px, -2px);
}

input:checked+.columnFilterSlider:after {
    width: 24px;
    border-radius: 50%;
    transform: translate(64px, 0px);
}


/* Rounded sliders */

.columnFilterSlider.round {
    border-radius: 34px;
}

.columnFilterSlider.round:before {
    border-radius: 50%;
}

.noOutline {
    outline: none;
    border: 0px solid !important;
    border-top-style: hidden;
    background-color: transparent !important;
    box-shadow: none;
    font-weight: bold;
    font-size: 14px;
}

.noOutline:hover {
    background-color: #eee;
    outline: none;
    opacity: 1;
}

.cancelDocumentTextArea {
    border: solid 2px #21a9e1;
    min-height: 100px;
    width: 100%;
    margin: 3px 0px 3px 0px;
    font-size: inherit;
}

.icon-color {
    color: #17a2b8;
}

.viewWidth50 {
    width: 50vw !important;
}

.viewWidth70 {
    width: 70vw !important;
}

.viewWidth85 {
    width: 85vw !important;
}

.separatorLine {
    border: 1.3px solid black;
    height: 35px;
}

.popup-btn-white {
    background: #FAFAFA;
    border: 0px #e0e0e0 solid;
    color: #303641;
    margin-right: 2px;
}

.popup-btn-white:hover {
    background: #ebebeb;
}

.popup-btn-white i {
    text-shadow: 0px 1px 0px #ebebeb;
}

.textfield-control {
    margin-left: 14px;
    margin-right: -14px;
}

#ddlUploadSFDocument .Select-arrow {
    margin-top: 10px;
}

#ddlUploadSFDocument .Select-control {
    height: 31px;
    border-radius: 2px !important;
    border: 1px solid #898D91;
}

#ddlUploadSFDocument .Select-input {
    height: 29px;
}

#ddlUploadSFDocument .Select-value {
    line-height: 30px;
}

#ddlUploadSFDocument .Select-multi-value-wrapper .Select-placeholder {
    color: #565A5E;
    padding-top: 0px;
    line-height: 30px;
    opacity: 0.5;
}

#ddlUploadSFDocument span {
    padding-right: 9px !important;
}

div.formNameList {
    position: absolute;
    z-index: 200;
    background-color: #fff;
    left: auto;
    text-align: left;
    list-style: none;
    border: 1px solid #aaa;
    white-space: nowrap;
}

div.formNameList>div.item {
    line-height: 24px;
    padding: 0 10px;
}

div.formNameList>div.item-highlighted {
    color: #fff;
    background-color: #0078d7;
}

.colorPaletteCoverPage {
    width: 300px;
    margin: auto;
    margin-top: 50px;
    border: 2px solid;
    border-radius: 8px;
    min-height: 220px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.colorPaletteCoverPage:after {
    border: solid transparent;
    border-width: 20px;
    top: 200px;
    left: 74%;
    content: " ";
    height: 244px;
    width: 300px;
    position: absolute;
    pointer-events: none;
    border-color: rgba(255, 255, 255, 0);
    border-left-color: inherit;
    border-radius: 0px 0px 0px;
    margin-left: 0px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(0deg);
    z-index: 0;
    box-shadow: 10px;
}

.previewContainer {
    min-height: 350px;
}

.headerContainer {
    padding: 5px;
}

.documentInfoContainer {
    background-Color: white;
    border: 2px solid #d3d3d3;
    margin: 15px;
    text-align: center;
}

.cpaInfo {
    font-size: 12px;
}

.companyLogoDiv {
    max-width: 80px;
    max-height: 36px;
    margin: auto;
}

.companyInfoDiv {
    font-size: 12px;
    padding: 10px;
    word-break: break-all;
}

.getStarted {
    position: absolute;
    top: 244px;
    right: 89px;
    background-color: #89c05a;
    font-size: 10px;
    transform: rotate(90deg);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    z-index: 1;
    width: 78px;
    text-align: center;
    border: 2px solid;
    border-bottom: 0;
    color: #fff;
    font-weight: 600;
}

.previewContact {
    margin-top: -24px;
    color: white;
    padding-left: 420px;
}

.sfSigner>.Select--single>.Select-control .Select-value {
    max-width: 97%;
}

.sf-compulsory-field {
    color: #c50505;
    font-size: 9px;
    position: absolute;
    padding-left: 7px;
    top: 8px;
}

.doc-access-auth-title {
    padding-top: 20px;
    padding-left: 20px;
}


/* Notification Settings  */

.notificationPopover {
    max-width: none;
    /* Max Width of the popover (depending on the container!) */
    font-size: 12px;
    overflow: hidden;
    width: 500px;
    height: 550px;
    resize: both;
    min-width: 300px;
    min-height: 250px;
}

.notificationPopover .popover-content {
    height: 100%;
}

#inbox-tab {
    height: 100%;
}

#inbox-tab .tab-content {
    height: 90%;
}

#inbox-tab .tab-content .tab-pane {
    height: 94%;
}

.settingsPanel {
    max-height: 47vh;
    overflow-y: auto;
}

.settings {
    color: grey;
    padding: 4px 2px;
    text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
    font-size: medium;
}

.settings:hover {
    color: grey;
}

.pad-settings {
    padding: 4px 8px;
}

.pad00 {
    padding: 0px;
}

.btn-settings {
    width: 40px;
}

.text-dodgerblue {
    color: dodgerblue;
    cursor: pointer;
}

.w30 {
    width: 30px;
}

.checkbox-helper {
    display: inline-block;
    position: absolute;
    right: 0px;
    z-index: 10000000;
}

.disableAnchor {
    pointer-events: none;
}

.enableAnchor {
    pointer-events: all;
}

.canvasProperty {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.4;
    border: 3px solid #037996;
}

.divCanvasProperty {
    border: 2px outset black;
    background: white;
    text-align: center;
    height: 100%;
    width: 100%;
    position: relative;
    margin: 0px;
}

.watermarkDraggerProperty {
    display: flex !important;
    border: solid 2px #037996;
    background: white;
    opacity: 0.6;
}

.watermarkIconwidth {
    width: 1.5em !important;
}

.divMainProperty {
    border: 2px outset black;
    background: white;
    text-align: center;
    height: 792px;
    width: 612px;
    position: relative;
    margin: 20px;
}

.moveableWatermark {
    font-family: "Roboto", sans-serif;
    position: relative;
    width: 300px;
    height: 100px;
    text-align: center;
    font-size: 40px;
    margin: 0px auto;
    font-weight: 100;
    letter-spacing: 1px;
    left: -150px;
}

.moveableWatermark span {
    position: absolute;
    top: 0px;
    left: 0px;
    transform: translate(0px, 0px);
    white-space: nowrap;
}

.switchToggle {
    position: relative;
    width: 44px;
    height: 18px;
    margin: 5px 0 4px 0;
}

.switchToggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

.w40 {
    width: 40px;
}

.list-title {
    font-weight: 600 !important;
    font-size: 15px !important;
    text-transform: capitalize;
}

.list-sub-title {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    color: rgba(0, 0, 0, .55) !important;
    font-size: 14px !important;
    text-transform: capitalize;
}

.disabled {
    cursor: not-allowed !important;
}

.disabled>div {
    cursor: not-allowed !important;
}

.checkbox label input[type="checkbox"][disabled] {
    cursor: not-allowed;
}

a.btn-default input[disabled] {
    cursor: not-allowed;
}

.toggle-switch-text {
    font-size: 16px;
    font-weight: normal;
    padding-top: 4px;
}

.contactIcon {
    width: 14px;
    margin: 0px 5px -5px 0px;
}


/* My Downloads*/

.my-download-title {
    color: grey;
    margin-right: 5px;
}

.my-download-file-icon {
    color: #1a8fbf;
    margin-left: 20px;
    margin-top: 10px;
}

.my-download-row-toggle {
    color: grey;
    margin-right: 5px;
    margin-top: 16px;
    margin-left: 24px;
    cursor: pointer;
}

.my-download-delete {
    margin-top: 16px;
    font-size: 20px;
    color: #dc3545 !important;
    cursor: pointer;
}

.my-download-expand {
    color: grey;
    text-align: left;
    margin-left: 37px;
    font-size: 12px;
}

.my-download-filter {
    border: hidden;
    color: grey;
}

.my-download-filter-row {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 244px
}

.my-download-status-icon {
    color: #23527c;
    font-size: 20px;
    margin-top: 16px;
}

.my-download-popover {
    color: grey;
    font-size: 12px;
}

#delegatee-table {
    width: 98%;
    background: #fff;
    margin-left: 21px;
    margin-top: -1px;
}
#delegatee-recyclebin-table{
    width: 98%;
    background: #fff;
    margin-left: 21px;
    margin-top: -1px;
}
#delegatee-table tr td {
    border: none;
    padding-left: 9px;
}
#delegatee-recyclebin-table tr td {
    border: none;
    padding-left: 15px;
}

.sf-upload-delete-confirm {
    margin-bottom: 0px;
}

.download-return-pop-up {
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


/*--Sort Icon allignment Styling in Grid for Delivered and Archive Return view  ---*/

.table-text-sort {
    display: inline-block;
    max-width: 65%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.devilvered-archived-screen th span.order {
    float: right;
}

.devilvered-archived-screen .react-bs-table table th {
    overflow: visible !important;
}

.signatureflow-report .sort-column[data-field] {
    overflow: visible !important;
}

.exedownload-icon {
    font-size: x-large;
    cursor: pointer;
    text-decoration: none !important;
    margin-left: 10px;
}

.full-height {
    height: 100%;
}

.btn-border {
    border: 1px #e0e0e0 solid;
}

.password-info {
    font-weight: normal;
    background-color: rgb(215, 240, 247);
    padding-right: 11px;
    padding-left: 5px;
}

.report-problem-field {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 15px;
}

.font700 {
    font-weight: 700;
}

.li-font-styling {
    font-family: "Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 12px !important;
}

.reminerToggleDisable .custom-toggle-switch-label {
    background: lightgrey !important
}

.quick-report-selection {
    display: inline-block;
    color: #333;
    background-color: #f0f0f1;
    border: none;
    padding: 5px 8px;
    float: left;
}

.no-border {
    border: none;
}

.SignatureArchivecheckbox {
    margin-top: 5px;
}

.SignatureRecyclecheckbox {
    margin-top: 5px;
}

.marB20 {
    margin-bottom: 20px !important;
}

.btn-icon-default {
    background: #ebebeb;
    border: 1px #e0e0e0 solid;
    color: #303641;
    margin-right: 2px;
    margin-bottom: 2px;
}

.btn-icon-default:hover {
    background: #ebebeb;
}

.btn-icon-default i {
    text-shadow: 0px 1px 0px #ebebeb;
}

.delegation-settings {
    margin-left: 15px;
    margin-top: 4px;
}

.modal .form-group {
    display: flex;
    /* margin-bottom: 0rem;*/
}

.button-group .dropdown-menu {
    padding: 8px 0px 8px 0px;
}

.button-group .dropdown-menu .ddl-icon {
    margin-left: 5px;
}

.modal-dialog .modal-footer .modal-footer-left {
    position: absolute;
    bottom: 20px;
    left: 10px;
}

.text-primary {
    color: #0973BA !important;
}

.gray-500-text-color {
    color: #6B7075 !important;
}

.gray-700-text-color {
    color: #404346 !important;
}

.alignItemsBaseline {
    align-items: baseline;
}

.font14 {
    font-size: 14px;
}

.font16 {
    font-size: 16px;
}

.font13 {
    font-size: 13px;
}

.font12 {
    font-size: 12px !important;
}

.marRauto {
    margin-right: auto;
}

.hidden {
    display: none !important;
}

.dispFR {
    display: flow-root !important;
}

.dispFlex {
    display: flex;
}

.dispBlock {
    display: block !important;
}

label {
    font-weight: 700;
}

.badge {
    color: #fff;
    background-color: #777;
    padding: 3px 7px;
    font-size: 12px;
    border-radius: 10px;
}

.popover-header {
    margin-top: 0px;
    font-size: 14px;
}


/*--------bootbox Start--------------*/

.bootbox .modal-dialog {
    max-width: 600px;
}

.bootbox .modal-dialog .modal-content {
    max-width: 500px;
}

.bootbox .modal-header {
    display: initial;
}

.bootbox-body {
    font-size: 16px;
}


/*--------bootbox End--------------*/

.react-bs-table .table th,
.react-bs-table .table td {
    padding: 0.2rem;
}

.dropdown,
.dropup {
    position: relative;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
}

.custom-control-label::before {
    display: none;
}

.custom-control {
    padding-left: 0px;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
}

.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
    width: 100% !important;
}

body .card {
    font-size: 14px;
}


/* Loading Screen */

.login-container {
    width: 100vw;
    height: 100vh;
    background-color: #F0F1F1;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
    transition: all 550ms ease-in-out;
}

.loading-text {
    margin-top: 50px;
}

.flex-column-center-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.outer-form-container {
    position: relative;
    content: '';
    left: 50%;
    bottom: 0;
    margin-left: -12.5px;
    border-style: solid;
    border-width: 13px 12.5px 0 12.5px;
    border-color: #ffffff transparent transparent transparent;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
    transition: all 550ms ease-in-out;
}

.login-form-container {
    text-align: center;
    padding: 15px 10px;
    background-color: #f7f7f7;
    height: 100vh;
}

.daimond {
    position: relative;
    width: 600px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    -moz-transition: all 550ms ease-in-out;
    -o-transition: all 550ms ease-in-out;
    -webkit-transition: all 550ms ease-in-out;
    transition: all 550ms ease-in-out;
}


/* Loading Screen */


/* dot-spin loader css start */

.dot-spin {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: transparent;
    color: transparent;
    box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656, 12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), 0 18px 0 0 rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0), -18px 0 0 0 rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
    animation: dot-spin 1.5s infinite linear;
}

@keyframes dot-spin {
    0%,
    100% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656, 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    12.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 0 #88c656, 12.727926px 12.727926px 0 0 #88c656, 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    25% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #88c656, 12.727926px 12.727926px 0 0 #88c656, 0 18px 0 0 #88c656, -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    37.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #88c656, 0 18px 0 0 #88c656, -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    50% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #88c656, -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 0 #88c656, -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
    }
    62.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0), 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #88c656, -18px 0 0 0 #88c656, -12.727926px -12.727926px 0 0 #88c656;
    }
    75% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 0 #88c656, -12.727926px -12.727926px 0 0 #88c656;
    }
    87.5% {
        box-shadow: 0 -18px 0 0 #88c656, 12.727926px -12.727926px 0 0 #88c656, 18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), -18px 0 0 -5px rgba(152, 128, 255, 0), -12.727926px -12.727926px 0 0 #88c656;
    }
}


/* dot-spin loader css end */

.tablePagination {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
}

.tablePagination .showPageNumber {
    color: #037996;
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 0px;
    margin-right: 0.5rem;
}

.tablePagination .showPageNumber li {
    list-style: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.tablePagination .showPageNumber span {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tablePagination .showPageNumber svg {
    width: 15px;
}

.tablePagination .showPageNumber li.currentPageNumber {
    background-color: #0973BA;
    color: white;
    font-weight: normal;
    padding: 3px 9px !important;
    cursor: default;
}

.tablePagination .showPageNumber li.paginationActionDisabled {
    color: #C4C6C8;
    background-color: #F0F1F1;
    cursor: not-allowed;
}

.tablePagination li {
    padding: 8px;
}

.tablePagination .configurePagination {
    color: gray;
    white-space: nowrap;
}

.tablePagination .configurePagination #pageSize {
    border: 1px solid #898D91;
    border-radius: 3.5px;
    padding: 4px 8px;
    outline: none;
    color: #565A5E;
    cursor: pointer;
    background-color: #FFFFFF;
}

.tablePagination .configurePagination #pageSize option {
    color: #565A5E;
}

.tablePagination .configurePagination .paginationActionDisabled {
    color: #C4C6C8 !important;
    background-color: #F0F1F1 !important;
    cursor: not-allowed !important;
    height: 100%;
    opacity: unset;
}

.tablePagination .configurePagination #goToPage {
    border: 1px solid #898D91;
    border-radius: 3.2px;
    outline: none;
    padding: 4px 8px;
    width: 55px;
    color: #565A5E;
    text-align: center;
    height: 100%;
}

.disabled-opacity {
    opacity: 0.65;
}

.justify-content-unset {
    justify-content: unset;
}

.blueModalHeader {
    background: #05386B;
}

.selectDropdownCaretRenderer:hover {
    fill: #666666 !important;
}

.bord-t-0-i {
    border-top: 0px !important;
}

.bord-b-1 {
    border-bottom: 1px solid #A6A9AC !important;
}

.default-indicator-batch {
    background: #F0F1F1;
    padding: 1px 5px;
    color: black;
    border: 1px solid #000000;
    border-radius: 4px;
    font-weight: 400;
}


/* ip-restriction page css starts */

.restrict-access-text,
.restrict-page-body {
    color: rgb(33, 37, 41);
    font-size: 12px;
    font-family: Mulish, sans-serif;
    font-weight: 400;
    text-align: start;
    padding: 0px 0px 0px 0px;
    line-height: 18px;
    display: block;
    box-sizing: border-box;
}

.restrict-access-text {
    margin: 0px 0px 15px 0px;
}

.restrict-page-body {
    margin: 0px 0px 0px 10px;
}

.restrict-access-text h1,
.restrict-access-text h4 {
    font-family: Mulish, sans-serif;
    font-weight: bolder;
    margin-block-start: 6px;
    margin-block-end: 6px;
    margin: 6px 0px 6px 0px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    text-align: start;
}

.restrict-access-text h1 {
    line-height: 28.8px;
    font-size: 24px;
}

.restrict-access-text h4 {
    font-size: 12px;
    line-height: 14.4px;
}

.login-link {
    box-sizing: border-box;
    color: rgb(51, 122, 183);
    cursor: pointer;
    font-family: Mulish, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-decoration: rgb(51, 122, 183);
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-thickness: auto;
}


/* ip-restriction css ends */

.input-clear-icon {
    cursor: pointer;
}

.finish,
.finish:hover {
    background: #669440 !important;
    border: 1px #669440 solid !important;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cpa-disabled-email{
    color: #495057 !important;
    background-color: #e9ecef !important;
    cursor: not-allowed !important;
    border: 1px solid #ced4da !important;
    opacity: 1;
}