@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../../font/glyphicons-halflings-regular.eot');
    src: url('../../font/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('../../font/glyphicons-halflings-regular.woff2') format('woff2'), url('../../font/glyphicons-halflings-regular.woff') format('woff'), url('../../font/glyphicons-halflings-regular.ttf') format('truetype'), url('../../font/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphicon-remove:before {
    content: url('../../images/CrossIcon.svg');
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    transform: translate(22%, -53%);
}
